var render = function () {
  var _vm$commercialCreditD, _vm$commercialCreditD2, _vm$commercialCreditD3, _vm$commercialCreditD4, _vm$commercialCreditD5, _vm$commercialCreditD6, _vm$commercialCreditD7, _vm$commercialCreditD8, _vm$commercialCreditD9, _vm$commercialCreditD10, _vm$commercialCreditD11, _vm$commercialCreditD12, _vm$commercialCreditD13, _vm$commercialCreditD14, _vm$commercialCreditD15, _vm$commercialCreditD16, _vm$commercialCreditD17, _vm$commercialCreditD18, _vm$commercialCreditD19, _vm$commercialCreditD20, _vm$commercialCreditD21;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isCreditDataLoading,
      expression: "isCreditDataLoading"
    }],
    staticClass: "application-form"
  }, [_c('el-card', {
    staticClass: "user-form-wrap"
  }, [_c('h3', {
    staticClass: "mb-4"
  }, [_vm._v(_vm._s(_vm.CONSUMER_CREDIT_FILES_TITLE))]), _vm._v(" "), _c('el-table', {
    staticClass: "full-width",
    attrs: {
      "data": _vm.tableData
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "fullName",
      "label": "Applicant"
    }
  }), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "prop": "creditScore"
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('p', [_vm._v(_vm._s(_vm.CREDIT_REPORT_CREDIT_SCORE_TITLE))]), _vm._v(" "), _c('p', [_vm._v(_vm._s(_vm.CREDIT_REPORT_COMPREHENSIVE_TITLE))])])], 2), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "prop": "oneScore"
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('p', [_vm._v(_vm._s(_vm.CREDIT_REPORT_CREDIT_SCORE_TITLE))]), _vm._v(" "), _c('p', [_vm._v(_vm._s(_vm.CREDIT_REPORT_ONE_SCORE_TITLE))])])], 2), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "prop": "createdAt",
      "label": _vm.CREDIT_REPORT_LAST_CREATED_AT_TITLE
    }
  }), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.CREDIT_REPORT_PRIVACY_SENT_AT,
      "width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.tableData.length > 0 && _vm.tableData[scope.$index] && _vm.tableData[scope.$index].eSignRequestedAt ? _c('div', {
          staticClass: "flex items-center"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/ok.svg"),
            "alt": "okay"
          }
        }), _vm._v(" "), _c('p', {
          staticClass: "ml-2"
        }, [_vm._v("\n                " + _vm._s(_vm.tableData[scope.$index].eSignRequestedAt))])]) : _c('div', {
          staticClass: "flex items-center"
        }, [_c('p', {
          staticClass: "ml-2"
        }, [_vm._v(_vm._s(_vm.CREDIT_REPORT_PENDING))])])];
      }
    }])
  }), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.CREDIT_REPORT_PRIVACY_SIGNED_AT,
      "width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.tableData.length > 0 && _vm.tableData[scope.$index] && _vm.tableData[scope.$index].eSignAt ? _c('div', {
          staticClass: "flex items-center"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/ok.svg"),
            "alt": "okay"
          }
        }), _vm._v(" "), _c('p', {
          staticClass: "ml-2"
        }, [_vm._v(_vm._s(_vm.tableData[scope.$index].eSignAt))])]) : _c('div', {
          staticClass: "flex items-center"
        }, [_c('p', {
          staticClass: "ml-2"
        }, [_vm._v(_vm._s(_vm.CREDIT_REPORT_PENDING))])])];
      }
    }])
  }), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.LAST_ERROR
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.tableData.length > 0 && _vm.tableData[scope.$index] && _vm.tableData[scope.$index].errorType && !_vm.hasDocumentUrl(scope) ? _c('div', {
          staticClass: "flex items-center"
        }, [_c('p', {
          class: {
            'text-danger': _vm.tableData[scope.$index].errorType
          }
        }, [_vm._v(_vm._s(_vm.mapErrorType(_vm.tableData[scope.$index].errorType)))])]) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.CREDIT_REPORT_TITLE
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', [!_vm.hasDocumentUrl(scope) ? _c('a', {
          staticClass: "el-button el-button--default el-button--small",
          class: _vm.disableRefetch(scope) ? 'is-disabled event-none' : '',
          attrs: {
            "loading": _vm.refetchButtonLoader
          },
          on: {
            "click": function ($event) {
              return _vm.refetch(scope);
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_CREDIT_REFETCH))]) : _c('a', {
          staticClass: "el-button el-button--default el-button--small",
          on: {
            "click": function ($event) {
              return _vm.download(scope);
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_DOWNLOAD))])])];
      }
    }])
  }), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.CREDIT_CHECK_TITLE
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          staticClass: "w-auto",
          attrs: {
            "disabled": !_vm.tableData[scope.$index].eSignAt || _vm.isApplicationSettled,
            "type": "primary",
            "size": "small"
          },
          nativeOn: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openModal(_vm.tableData[scope.$index]);
            }
          }
        }, [_vm._v("\n              Run\n            ")])];
      }
    }])
  }), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.LABEL_CREDIT_HISTORY
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          staticClass: "w-auto",
          attrs: {
            "size": "small",
            "disabled": !_vm.tableData[scope.$index].eSignAt && !_vm.hasDocumentUrl(scope) && !_vm.tableData[scope.$index].errorType,
            "plain": ""
          },
          on: {
            "click": function ($event) {
              return _vm.showCreditHistoryDrawer(scope.$index);
            }
          }
        }, [_vm._v("\n              View\n            ")])];
      }
    }])
  })], 1), _vm._v(" "), _c('CreditHistoryDrawer', {
    attrs: {
      "primary-data": _vm.primaryData,
      "secondary-data": _vm.secondaryData,
      "check-application-is-settled": _vm.checkApplicationIsSettled
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isCreditDataLoading,
      expression: "isCreditDataLoading"
    }],
    staticClass: "application-form mt-3"
  }), _vm._v(" "), _vm.sharedData.applicationType == _vm.LABEL_COMMERCIAL ? _c('el-card', {
    staticClass: "user-form-wrap"
  }, [_c('h3', {
    staticClass: "mb-4"
  }, [_vm._v(_vm._s(_vm.COMMERCIAL_CREDIT_FILES_TITLE))]), _vm._v(" "), _c('el-table', {
    staticClass: "full-width",
    attrs: {
      "data": [_vm.commercialCreditData]
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "businessName",
      "label": _vm.LABEL_BUSINESS_NAME
    }
  }), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.CREDIT_REPORT_CREDIT_SCORE_TITLE
    }
  }, [[_c('p', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s((_vm$commercialCreditD = (_vm$commercialCreditD2 = _vm.commercialCreditData.creditEnquiries) === null || _vm$commercialCreditD2 === void 0 ? void 0 : (_vm$commercialCreditD3 = _vm$commercialCreditD2.at(-1)) === null || _vm$commercialCreditD3 === void 0 ? void 0 : _vm$commercialCreditD3.creditScore) !== null && _vm$commercialCreditD !== void 0 ? _vm$commercialCreditD : _vm.CREDIT_REPORT_PENDING))])]], 2), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.CREDIT_REPORT_LAST_CREATED_AT_TITLE
    }
  }, [[_c('p', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s((_vm$commercialCreditD4 = _vm.commercialCreditData.creditEnquiries) !== null && _vm$commercialCreditD4 !== void 0 && (_vm$commercialCreditD5 = _vm$commercialCreditD4.at(-1)) !== null && _vm$commercialCreditD5 !== void 0 && _vm$commercialCreditD5.createdAt ? _vm.format(new Date(_vm.commercialCreditData.creditEnquiries.at(-1).createdAt), 'dd-MM-yyyy') : _vm.CREDIT_REPORT_PENDING))])]], 2), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.LABEL_NUMBER_OF_DIRECTORS
    }
  }, [[_c('p', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s((_vm$commercialCreditD6 = (_vm$commercialCreditD7 = _vm.commercialCreditData.creditEnquiries) === null || _vm$commercialCreditD7 === void 0 ? void 0 : (_vm$commercialCreditD8 = _vm$commercialCreditD7.at(-1)) === null || _vm$commercialCreditD8 === void 0 ? void 0 : _vm$commercialCreditD8.directors.length) !== null && _vm$commercialCreditD6 !== void 0 ? _vm$commercialCreditD6 : _vm.CREDIT_REPORT_PENDING))])]], 2), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.LABEL_NUMBER_OF_SHAREHOLDERS
    }
  }, [[_c('p', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s((_vm$commercialCreditD9 = (_vm$commercialCreditD10 = _vm.commercialCreditData.creditEnquiries) === null || _vm$commercialCreditD10 === void 0 ? void 0 : (_vm$commercialCreditD11 = _vm$commercialCreditD10.at(-1)) === null || _vm$commercialCreditD11 === void 0 ? void 0 : _vm$commercialCreditD11.shareholders.length) !== null && _vm$commercialCreditD9 !== void 0 ? _vm$commercialCreditD9 : _vm.CREDIT_REPORT_PENDING))])]], 2), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.LAST_ERROR
    }
  }, [[_vm.commercialCreditData && _vm.commercialCreditData.creditEnquiries && (_vm$commercialCreditD12 = _vm.commercialCreditData.creditEnquiries) !== null && _vm$commercialCreditD12 !== void 0 && (_vm$commercialCreditD13 = _vm$commercialCreditD12.at(-1)) !== null && _vm$commercialCreditD13 !== void 0 && _vm$commercialCreditD13.errorType ? _c('div', {
    staticClass: "flex items-center"
  }, [_c('p', {
    class: {
      'text-danger': (_vm$commercialCreditD14 = _vm.commercialCreditData.creditEnquiries) === null || _vm$commercialCreditD14 === void 0 ? void 0 : (_vm$commercialCreditD15 = _vm$commercialCreditD14.at(-1)) === null || _vm$commercialCreditD15 === void 0 ? void 0 : _vm$commercialCreditD15.errorType
    }
  }, [_vm._v(_vm._s(_vm.mapErrorType((_vm$commercialCreditD16 = _vm.commercialCreditData.creditEnquiries) === null || _vm$commercialCreditD16 === void 0 ? void 0 : (_vm$commercialCreditD17 = _vm$commercialCreditD16.at(-1)) === null || _vm$commercialCreditD17 === void 0 ? void 0 : _vm$commercialCreditD17.errorType)))])]) : _vm._e()]], 2), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.CREDIT_REPORT_TITLE
    }
  }, [[_c('div', [!((_vm$commercialCreditD18 = _vm.commercialCreditData.creditEnquiries) !== null && _vm$commercialCreditD18 !== void 0 && (_vm$commercialCreditD19 = _vm$commercialCreditD18.at(-1)) !== null && _vm$commercialCreditD19 !== void 0 && _vm$commercialCreditD19.creditFileUrl) ? _c('a', {
    staticClass: "el-button el-button--default el-button--small",
    class: _vm.disableRefetch(undefined, true) ? 'is-disabled event-none' : '',
    attrs: {
      "loading": _vm.refetchButtonLoader
    },
    on: {
      "click": function ($event) {
        return _vm.refetch({}, true);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CREDIT_REFETCH))]) : _c('a', {
    staticClass: "el-button el-button--default el-button--small",
    on: {
      "click": function ($event) {
        return _vm.download(_vm.scope, true);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_DOWNLOAD))])])]], 2), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.CREDIT_CHECK_TITLE
    }
  }, [[_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "disabled": !_vm.commercialCreditData.aBN,
      "type": "primary",
      "size": "small"
    },
    nativeOn: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openModal(undefined, true);
      }
    }
  }, [_vm._v("\n            Run\n          ")])]], 2), _vm._v(" "), _c('el-table-column', {
    attrs: {
      "label": _vm.LABEL_CREDIT_HISTORY
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          staticClass: "w-auto",
          attrs: {
            "size": "small",
            "plain": ""
          },
          on: {
            "click": function ($event) {
              return _vm.showCreditHistoryDrawer(scope.$index, true);
            }
          }
        }, [_vm._v("\n            View\n          ")])];
      }
    }], null, false, 2300782475)
  })], 1), _vm._v(" "), _vm._l((_vm$commercialCreditD20 = _vm.commercialCreditData.creditEnquiries) === null || _vm$commercialCreditD20 === void 0 ? void 0 : (_vm$commercialCreditD21 = _vm$commercialCreditD20.at(-1)) === null || _vm$commercialCreditD21 === void 0 ? void 0 : _vm$commercialCreditD21.scoringDetails, function (scoringDetail, index) {
    return _c('div', {
      key: index,
      staticClass: "ml-8 mt-4 flex flex-row",
      staticStyle: {
        "min-height": "30px"
      }
    }, [scoringDetail.type == 'warning' ? _c('img', {
      staticClass: "mr-4",
      attrs: {
        "src": require("@/assets/icons/info.svg"),
        "alt": "okay"
      }
    }) : _vm._e(), _vm._v(" "), scoringDetail.type == 'contribution' ? _c('img', {
      staticClass: "mr-4",
      attrs: {
        "src": require("@/assets/icons/ok.svg"),
        "alt": "okay"
      }
    }) : _vm._e(), _vm._v(" "), scoringDetail.type == 'error' ? _c('img', {
      staticClass: "mr-4",
      attrs: {
        "src": require("@/assets/icons/alert-triangle.svg"),
        "alt": "okay"
      }
    }) : _vm._e(), scoringDetail.type == 'contribution' ? _c('p', {
      staticClass: "mt-2 text-sm"
    }, [_c('b', [_vm._v(_vm._s(scoringDetail.factor) + ":")]), _vm._v(" " + _vm._s(scoringDetail.description))]) : _c('p', {
      staticClass: "mt-2 text-sm"
    }, [_vm._v(_vm._s(scoringDetail.description))])]);
  })], 2) : _vm._e(), _vm._v(" "), _c('creditReportDialog', {
    attrs: {
      "dialog-data": _vm.dialogData,
      "show-dialog": _vm.showDialog,
      "dialog-for": _vm.dialogFor,
      "applicant-id": _vm.applicantId
    }
  }), _vm._v(" "), _c('creditFetchDialog', {
    attrs: {
      "show-dialog": _vm.showFetchDialog
    }
  }), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('div', {
    staticClass: "border-r border-light-grey mr-6 pr-6"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.backToPrevious();
      }
    }
  }, [_vm._v("Back")])], 1), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.submit(true);
      }
    }
  }, [_vm._v("Next")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }