import { render, staticRenderFns } from "./Settlement.vue?vue&type=template&id=b6a780ea&scoped=true&"
import script from "./Settlement.vue?vue&type=script&lang=js&"
export * from "./Settlement.vue?vue&type=script&lang=js&"
import style0 from "./Settlement.vue?vue&type=style&index=0&id=b6a780ea&lang=scss&scoped=true&"
import style1 from "./Settlement.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6a780ea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SettlementSubTab: require('/opt/build/repo/components/SettlementSubTab.vue').default,CpdSendConfirmDialog: require('/opt/build/repo/components/CpdSendConfirmDialog.vue').default})
