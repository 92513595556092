var render = function () {
  var _vm$complianceData, _vm$complianceData$pr, _vm$complianceData2, _vm$complianceData3, _vm$complianceData3$p, _vm$complianceData3$p2, _vm$complianceData5, _vm$complianceData6, _vm$complianceData6$p, _vm$complianceData6$p2, _vm$complianceData8, _vm$complianceData8$p, _vm$complianceData8$p2, _vm$complianceData16, _vm$complianceData16$, _vm$complianceData16$2, _vm$complianceData16$3, _vm$complianceData17, _vm$complianceData17$, _vm$complianceData17$2, _vm$complianceData17$3, _vm$complianceData18, _vm$complianceData18$, _vm$complianceData18$2, _vm$complianceData19, _vm$complianceData19$, _vm$complianceData19$2, _vm$complianceData19$3, _vm$complianceData20, _vm$complianceData20$, _vm$complianceData20$2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }]
  }, [_vm.complianceForm ? _c('el-card', {
    staticClass: "application-form compliance-tab pb-child-none"
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "compliance-header-text"
    }
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_COMPLIANCE))])]), _vm._v(" "), _c('div', {
    staticClass: "mb-6",
    attrs: {
      "data-selector": "privacy-section"
    }
  }, [_c('PrivacyFormHeader', {
    attrs: {
      "handle-documents-url": _vm.handleDocumentsUrl,
      "shared-documents": _vm.getSharedDocuments,
      "is-commercial": _vm.isCommercial,
      "title": _vm.isCommercial ? _vm.TITLE_FOR_COMPLIANCE_PRIVACY_FORM_COMMERCIAL : _vm.TITLE_FOR_COMPLIANCE_PRIVACY_GUIDE_QUOTE,
      "description": _vm.isCommercial ? _vm.DESCRIPTION_FOR_COMPLIANCE_PRIVACY_FORM_COMMERCIAL : _vm.DESCRIPTION_FOR_COMPLIANCE_PRIVACY_GUIDE_QUOTE,
      "background-class": _vm.isPrivacyFormAreSigned && (_vm.hasPrivacyForm || _vm.hasCreditGuide || _vm.hasQuote || _vm.hasDocusignCert) ? 'el-alert--success' : 'bg-primary el-alert--primary'
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [!_vm.hasPrivacyForm && !_vm.hasCreditGuide && !_vm.hasQuote && !_vm.hasDocusignCert || !_vm.isPrivacyFormAreSigned ? _c('span', {
          staticClass: "rounded-fifty border border-dark order-label text-center text-xs mr-4"
        }, [_vm._v("1")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 403136384)
  }), _vm._v(" "), _vm.complianceData.primaryData && _vm.complianceData.primaryData.primaryApplicant ? _c('div', {
    class: _vm.doesSecondaryApplicantExist ? 'border-b border-light-grey pb-6 mb-6' : 'mb-6'
  }, [_c('el-row', {
    staticClass: "mb-child-xs-4",
    attrs: {
      "gutter": 16
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "md": 6,
      "xl": 3
    }
  }, [_vm.complianceData.primaryData && _vm.complianceData.primaryData.primaryApplicant && _vm.complianceData.primaryData.primaryApplicant.firstName ? _c('p', {
    staticClass: "mb-2 text-xs font-extrabold",
    attrs: {
      "data-selector": "p-applicant-name"
    }
  }, [_vm._v(_vm._s(_vm.complianceData.primaryData.primaryApplicant.firstName) + "\n              " + _vm._s(_vm.complianceData.primaryData.primaryApplicant.lastName))]) : _vm._e(), _vm._v(" "), _vm.complianceData.primaryData.primaryApplicant ? _c('p', {
    staticClass: "text-xs",
    attrs: {
      "data-selector": "applicant-type-primary-text"
    }
  }, [_vm._v("\n              Primary applicant\n            ")]) : _vm._e()]), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "md": 6,
      "xl": 5
    }
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_SENT_AT))]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mb-2 compliance-documents-label"
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_DATE) + "\n              "), _c('span', {
    staticClass: "compliance-documents-value",
    attrs: {
      "data-selector": "p-last-sent-date"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.complianceData.primaryData.eSignRequestedAt ? _vm.format(new Date(_vm.complianceData.primaryData.eSignRequestedAt), 'dd/MM/yyyy hh:mm aaa') : '--'))])]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mb-2 compliance-documents-label"
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_MOBILE) + "\n              "), _c('span', {
    staticClass: "compliance-documents-value",
    attrs: {
      "data-selector": "p-mobile"
    }
  }, [_vm._v(_vm._s(_vm.complianceData.primaryData.primaryApplicant.mobile ? _vm.complianceData.primaryData.primaryApplicant.mobile : '--'))])]), _vm._v(" "), _c('p', {
    staticClass: "text-xs compliance-documents-label"
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_EMAIL) + "\n              "), _c('span', {
    staticClass: "compliance-documents-value",
    attrs: {
      "data-selector": "p-email"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.complianceData.primaryData.primaryApplicant.email ? _vm.complianceData.primaryData.primaryApplicant.email : '--'))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "md": 6,
      "xl": 4
    }
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_SIGNED_AT))]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mb-2 compliance-documents-label",
    attrs: {
      "data-selector": "p-last-signed-at"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_DATE) + "\n              "), _c('span', {
    staticClass: "compliance-documents-value"
  }, [_vm._v("\n                " + _vm._s(_vm.complianceData.primaryData.eSignAt ? ("" + (_vm.format(new Date(_vm.complianceData.primaryData.eSignAt), 'dd/MM/yyyy hh:mm aaa')) + (_vm.complianceData.primaryData.eSignIsOfflineUpload ? ' (Offline Upload)' : '')) : '--'))])]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mb-2 compliance-documents-label"
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_IP) + "\n              "), _c('span', {
    staticClass: "compliance-documents-value",
    attrs: {
      "data-selector": "p-ip-address"
    }
  }, [_vm._v(_vm._s(((_vm$complianceData = _vm.complianceData) === null || _vm$complianceData === void 0 ? void 0 : (_vm$complianceData$pr = _vm$complianceData.primaryData) === null || _vm$complianceData$pr === void 0 ? void 0 : _vm$complianceData$pr.eSignIp) || '--'))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "md": 6
    }
  }, [_c('div', [_c('el-row', [!_vm.hasDocusignEnvelopeSigned((_vm$complianceData2 = _vm.complianceData) === null || _vm$complianceData2 === void 0 ? void 0 : _vm$complianceData2.primaryData) ? _c('el-button', {
    staticClass: "w-auto mb-4",
    class: {
      'compliance-btn': _vm.hasDocusignEnvelope()
    },
    attrs: {
      "type": "primary",
      "disabled": !((_vm$complianceData3 = _vm.complianceData) !== null && _vm$complianceData3 !== void 0 && (_vm$complianceData3$p = _vm$complianceData3.primaryData) !== null && _vm$complianceData3$p !== void 0 && (_vm$complianceData3$p2 = _vm$complianceData3$p.addresses[0]) !== null && _vm$complianceData3$p2 !== void 0 && _vm$complianceData3$p2.address) ? true : false,
      "data-selector": "p-send-resend-btn"
    },
    on: {
      "click": function ($event) {
        var _vm$complianceData4;

        _vm.hasDocusignEnvelopeSent((_vm$complianceData4 = _vm.complianceData) === null || _vm$complianceData4 === void 0 ? void 0 : _vm$complianceData4.primaryData) ? _vm.sendESignEmailRequest(_vm.complianceData.primaryData.primaryApplicant._id, true) : _vm.sendESignEmailRequest(_vm.complianceData.primaryData.primaryApplicant._id);
      }
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.hasDocusignEnvelopeSent((_vm$complianceData5 = _vm.complianceData) === null || _vm$complianceData5 === void 0 ? void 0 : _vm$complianceData5.primaryData) ? 'Resend' : 'Send') + "\n                ")]) : _vm._e()], 1), _vm._v(" "), _c('el-row', [_vm.hasDocusignEnvelope() ? _c('el-button', {
    staticClass: "w-auto mb-4 compliance-btn",
    attrs: {
      "plain": "",
      "disabled": !((_vm$complianceData6 = _vm.complianceData) !== null && _vm$complianceData6 !== void 0 && (_vm$complianceData6$p = _vm$complianceData6.primaryData) !== null && _vm$complianceData6$p !== void 0 && (_vm$complianceData6$p2 = _vm$complianceData6$p.addresses[0]) !== null && _vm$complianceData6$p2 !== void 0 && _vm$complianceData6$p2.address) ? true : false,
      "data-selector": "p-send-resend-btn"
    },
    on: {
      "click": function ($event) {
        var _vm$complianceData7, _vm$complianceData7$p;

        return _vm.openResendConfirmationDialog((_vm$complianceData7 = _vm.complianceData) === null || _vm$complianceData7 === void 0 ? void 0 : (_vm$complianceData7$p = _vm$complianceData7.primaryData) === null || _vm$complianceData7$p === void 0 ? void 0 : _vm$complianceData7$p.primaryApplicant._id);
      }
    }
  }, [_vm._v("\n                  New Request\n                ")]) : _vm._e()], 1)], 1), _vm._v(" "), !((_vm$complianceData8 = _vm.complianceData) !== null && _vm$complianceData8 !== void 0 && (_vm$complianceData8$p = _vm$complianceData8.primaryData) !== null && _vm$complianceData8$p !== void 0 && (_vm$complianceData8$p2 = _vm$complianceData8$p.addresses[0]) !== null && _vm$complianceData8$p2 !== void 0 && _vm$complianceData8$p2.address) ? _c('div', {
    staticClass: "mb-6 w-auto inline-flex alertMessage el-alert el-alert--primary text-black is-light warning-address-details",
    attrs: {
      "role": "alert",
      "data-selector": "p-add-detail-alert"
    }
  }, [_c('img', {
    staticClass: "warning-address-details-img",
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title",
    attrs: {
      "data-selector": "p-add-detail-alert-text"
    }
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_INFO_ADDRESS_DETAILS))])])]) : _vm._e()]), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 6
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.doesSecondaryApplicantExist ? _vm._l(_vm.complianceData.secondaryData, function (otherApplicant, index) {
    var _vm$complianceData9, _vm$complianceData9$s, _vm$complianceData10, _vm$complianceData11, _vm$complianceData11$, _vm$complianceData11$2, _vm$complianceData13, _vm$complianceData14, _vm$complianceData14$, _vm$complianceData14$2, _vm$complianceData15, _vm$complianceData15$, _vm$complianceData15$2;

    return _c('el-row', {
      key: index,
      class: index !== _vm.complianceData.secondaryData.length - 1 ? 'border-b border-light-grey mb-6 pb-6' : '',
      attrs: {
        "gutter": 16
      }
    }, [_c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 12,
        "md": 6,
        "xl": 3
      }
    }, [_c('p', {
      staticClass: "mb-2 text-xs font-extrabold",
      attrs: {
        "data-selector": 's-applicant-name_' + index
      }
    }, [_vm._v(_vm._s(_vm.complianceData.secondaryData[index].applicant.firstName) + "\n              " + _vm._s(_vm.complianceData.secondaryData[index].applicant.lastName))]), _vm._v(" "), _vm.complianceData.secondaryData[index] ? _c('p', {
      staticClass: "text-xs",
      attrs: {
        "data-selector": 's-applicant-type-text_' + index
      }
    }, [_vm._v("\n              " + _vm._s(_vm.isCommercial ? _vm.complianceData.secondaryData[index].designation : 'Secondary applicant') + "\n            ")]) : _vm._e()]), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 12,
        "md": 6,
        "xl": 5
      }
    }, [_c('p', {
      staticClass: "mb-2 text-xs font-extrabold"
    }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_SENT_AT))]), _vm._v(" "), _c('p', {
      staticClass: "text-xs mb-2 compliance-documents-label"
    }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_DATE) + "\n              "), _c('span', {
      staticClass: "compliance-documents-value",
      attrs: {
        "data-selector": 's-last-sent-date_' + index
      }
    }, [_vm._v("\n                " + _vm._s(_vm.complianceData.secondaryData[index] && _vm.complianceData.secondaryData[index].eSignRequestedAt ? _vm.format(new Date(_vm.complianceData.secondaryData[index].eSignRequestedAt), 'dd/MM/yyyy hh:mm aaa') : '--'))])]), _vm._v(" "), _c('p', {
      staticClass: "text-xs mb-2 compliance-documents-label"
    }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_MOBILE) + "\n              "), _c('span', {
      staticClass: "compliance-documents-value",
      attrs: {
        "data-selector": 's-mobile_' + index
      }
    }, [_vm._v(_vm._s(_vm.complianceData.secondaryData[index].applicant.mobile ? _vm.complianceData.secondaryData[index].applicant.mobile : '--'))])]), _vm._v(" "), _c('p', {
      staticClass: "text-xs compliance-documents-label"
    }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_EMAIL) + "\n              "), _c('span', {
      staticClass: "compliance-documents-value",
      attrs: {
        "data-selector": 's-email_' + index
      }
    }, [_vm._v("\n                " + _vm._s(_vm.complianceData.secondaryData[index].applicant.email ? _vm.complianceData.secondaryData[index].applicant.email : '--'))])])]), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 12,
        "md": 6,
        "xl": 4
      }
    }, [_c('p', {
      staticClass: "mb-2 text-xs font-extrabold"
    }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_SIGNED_AT))]), _vm._v(" "), _c('p', {
      staticClass: "text-xs mb-2 compliance-documents-label"
    }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_DATE) + "\n              "), _c('span', {
      staticClass: "compliance-documents-value",
      attrs: {
        "data-selector": 's-last-signed-at_' + index
      }
    }, [_vm._v(_vm._s(_vm.complianceData.secondaryData[index].eSignAt ? ("" + (_vm.format(new Date(_vm.complianceData.secondaryData[index].eSignAt), 'dd/MM/yyyy hh:mm aaa')) + (_vm.complianceData.secondaryData[index].eSignIsOfflineUpload ? ' (Offline Upload)' : '')) : '--'))])]), _vm._v(" "), _c('p', {
      staticClass: "text-xs mb-2 compliance-documents-label"
    }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_IP) + "\n              "), _c('span', {
      staticClass: "compliance-documents-value",
      attrs: {
        "data-selector": 's-ip-address_' + index
      }
    }, [_vm._v("\n                " + _vm._s(((_vm$complianceData9 = _vm.complianceData) === null || _vm$complianceData9 === void 0 ? void 0 : (_vm$complianceData9$s = _vm$complianceData9.secondaryData[index]) === null || _vm$complianceData9$s === void 0 ? void 0 : _vm$complianceData9$s.eSignIp) || '--') + "\n              ")])])]), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 12,
        "md": 6
      }
    }, [_c('div', [_c('el-row', [!_vm.hasDocusignEnvelopeSigned((_vm$complianceData10 = _vm.complianceData) === null || _vm$complianceData10 === void 0 ? void 0 : _vm$complianceData10.secondaryData[index]) ? _c('el-button', {
      staticClass: "w-auto mb-4",
      class: {
        'compliance-btn': _vm.hasDocusignEnvelope()
      },
      attrs: {
        "type": "primary",
        "disabled": !((_vm$complianceData11 = _vm.complianceData) !== null && _vm$complianceData11 !== void 0 && (_vm$complianceData11$ = _vm$complianceData11.secondaryData[index]) !== null && _vm$complianceData11$ !== void 0 && (_vm$complianceData11$2 = _vm$complianceData11$.addresses[0]) !== null && _vm$complianceData11$2 !== void 0 && _vm$complianceData11$2.address) ? true : false,
        "data-selector": "p-send-resend-btn"
      },
      on: {
        "click": function ($event) {
          var _vm$complianceData12;

          _vm.hasDocusignEnvelopeSent((_vm$complianceData12 = _vm.complianceData) === null || _vm$complianceData12 === void 0 ? void 0 : _vm$complianceData12.secondaryData[index]) ? _vm.sendESignEmailRequest(_vm.complianceData.secondaryData[index].applicant._id, true) : _vm.sendESignEmailRequest(_vm.complianceData.secondaryData[index].applicant._id);
        }
      }
    }, [_vm._v("\n                  " + _vm._s(_vm.hasDocusignEnvelopeSent((_vm$complianceData13 = _vm.complianceData) === null || _vm$complianceData13 === void 0 ? void 0 : _vm$complianceData13.secondaryData[index]) ? 'Resend' : 'Send') + "\n                ")]) : _vm._e()], 1), _vm._v(" "), _c('el-row', [_vm.hasDocusignEnvelope() ? _c('el-button', {
      staticClass: "w-auto mb-4 compliance-btn",
      attrs: {
        "plain": "",
        "disabled": !((_vm$complianceData14 = _vm.complianceData) !== null && _vm$complianceData14 !== void 0 && (_vm$complianceData14$ = _vm$complianceData14.secondaryData[index]) !== null && _vm$complianceData14$ !== void 0 && (_vm$complianceData14$2 = _vm$complianceData14$.addresses[0]) !== null && _vm$complianceData14$2 !== void 0 && _vm$complianceData14$2.address) ? true : false,
        "data-selector": 's-send-resend-btn_' + index
      },
      on: {
        "click": function ($event) {
          return _vm.openResendConfirmationDialog(_vm.complianceData.secondaryData[index].applicant._id);
        }
      }
    }, [_vm._v("New Request")]) : _vm._e()], 1)], 1), _vm._v(" "), !((_vm$complianceData15 = _vm.complianceData) !== null && _vm$complianceData15 !== void 0 && (_vm$complianceData15$ = _vm$complianceData15.secondaryData[index]) !== null && _vm$complianceData15$ !== void 0 && (_vm$complianceData15$2 = _vm$complianceData15$.addresses[0]) !== null && _vm$complianceData15$2 !== void 0 && _vm$complianceData15$2.address) ? _c('div', {
      staticClass: "mb-6 w-auto inline-flex alertMessage el-alert el-alert--primary text-black is-light warning-address-details",
      attrs: {
        "role": "alert",
        "data-selector": 's-add-detail-alert_' + index
      }
    }, [_c('img', {
      staticClass: "warning-address-details-img",
      attrs: {
        "src": require("@/assets/icons/alert-triangle.svg"),
        "alt": "okay"
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "el-alert__content"
    }, [_c('span', {
      staticClass: "el-alert__title"
    }, [_vm._v(_vm._s(_vm.TITLE_FOR_INFO_ADDRESS_DETAILS))])])]) : _vm._e()]), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 24,
        "md": 6
      }
    })], 1);
  }) : _vm._e()], 2), _vm._v(" "), _c('ResendDocusignEnvelopeDialog', {
    attrs: {
      "dialog-opened": _vm.resendConfirmationDialog,
      "applicant-id": _vm.selectedApplicantForResendDocusignEnvelope
    }
  }), _vm._v(" "), _vm.hasLoanTypeVisible && !_vm.isCommercial ? _c('div', {
    staticClass: "mb-6"
  }, [_c('div', {
    staticClass: "el-alert is-light text-black mb-6",
    class: _vm.getPreliminaryUpdatedAt ? 'el-alert--success' : 'bg-primary el-alert--primary',
    attrs: {
      "role": "alert",
      "data-selector": "prelim-alert"
    }
  }, [!_vm.getPreliminaryUpdatedAt ? _c('span', {
    staticClass: "rounded-fifty border border-dark order-label text-center text-xs mr-4"
  }, [_vm._v("2")]) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay",
      "data-selector": "prelim-check-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "prelim-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_COMPLIANCE_ASSESSMENT))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description"
  }, [_vm._v(_vm._s(_vm.DESCRIPTION_FOR_COMPLIANCE_ASSESSMENT))])]), _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "plain": "",
      "disabled": !_vm.getPreliminaryUpdatedAt,
      "data-selector": "view-prelim-doc-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.handleDocumentsUrl(_vm.LABEL_PRELIMINARY_ASSESSMENT);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_VIEW))])], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 16
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 6
    }
  }, [!((_vm$complianceData16 = _vm.complianceData) !== null && _vm$complianceData16 !== void 0 && (_vm$complianceData16$ = _vm$complianceData16.sharedData) !== null && _vm$complianceData16$ !== void 0 && (_vm$complianceData16$2 = _vm$complianceData16$.loanDetails) !== null && _vm$complianceData16$2 !== void 0 && (_vm$complianceData16$3 = _vm$complianceData16$2.submission) !== null && _vm$complianceData16$3 !== void 0 && _vm$complianceData16$3.submittedAt) ? _c('div', {
    staticClass: "mb-6 w-auto inline-flex alertMessage el-alert el-alert--primary text-black is-light warning-address-details",
    attrs: {
      "role": "alert",
      "data-selector": "prelim-loan-details-alert"
    }
  }, [_c('img', {
    staticClass: "warning-address-details-img",
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title",
    attrs: {
      "data-selector": "loan-details-not-submitted-text"
    }
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_INFO_LOAN_DETAILS))])])]) : _vm._e(), _vm._v(" "), _c('p', {
    staticClass: "mb-2 text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_GENERATED_AT))]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mb-2 compliance-documents-label"
  }, [_vm._v("\n            " + _vm._s(_vm.LABEL_FOR_DATE) + "\n            "), _c('span', {
    staticClass: "compliance-documents-value",
    attrs: {
      "data-selector": "prelim-last-generated-at"
    }
  }, [_vm._v(_vm._s(_vm.getPreliminaryUpdatedAt ? _vm.format(new Date(_vm.getPreliminaryUpdatedAt), 'dd/MM/yyyy hh:mm aaa') : '--'))])])])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.hasLoanTypeVisible && !_vm.isCommercial ? _c('div', [_c('div', {
    staticClass: "el-alert text-black is-light mb-6",
    class: _vm.getCpdUpdatedAt ? 'el-alert--success' : 'bg-primary el-alert--primary',
    attrs: {
      "role": "alert",
      "data-selector": "cpd-alert"
    }
  }, [!_vm.getCpdUpdatedAt ? _c('span', {
    staticClass: "rounded-fifty border border-dark order-label text-center text-xs mr-4"
  }, [_vm._v("3")]) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay",
      "data-selector": "cpd-alert-check-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "cpd-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_COMPLIANCE_PROPOSAL_DISCLOSURE))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description"
  }, [_vm._v(_vm._s(_vm.DESCRIPTION_FOR_COMPLIANCE_CLOSURE_PROPOSAL))])]), _vm._v(" "), _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "plain": "",
      "disabled": !_vm.getCpdUpdatedAt,
      "data-selector": "cpd-view-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.handleDocumentsUrl(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_VIEW))]), _vm._v(" "), (_vm$complianceData17 = _vm.complianceData) !== null && _vm$complianceData17 !== void 0 && (_vm$complianceData17$ = _vm$complianceData17.sharedData) !== null && _vm$complianceData17$ !== void 0 && (_vm$complianceData17$2 = _vm$complianceData17$.loanDetails) !== null && _vm$complianceData17$2 !== void 0 && (_vm$complianceData17$3 = _vm$complianceData17$2.submission) !== null && _vm$complianceData17$3 !== void 0 && _vm$complianceData17$3.submittedAt ? _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "loading": _vm.proposalButtonLoading,
      "type": "primary",
      "disabled": _vm.isApplicationSettled,
      "data-selector": "cpd-send-resend-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.sendProposal();
      }
    }
  }, [_vm._v("\n          " + _vm._s((_vm$complianceData18 = _vm.complianceData) !== null && _vm$complianceData18 !== void 0 && (_vm$complianceData18$ = _vm$complianceData18.sharedData) !== null && _vm$complianceData18$ !== void 0 && (_vm$complianceData18$2 = _vm$complianceData18$.loanDetails) !== null && _vm$complianceData18$2 !== void 0 && _vm$complianceData18$2.creditProposalDisclosureSentAt ? 'Resend' : 'Send') + "\n        ")]) : _vm._e()], 1), _vm._v(" "), _vm.complianceData.primaryData && _vm.complianceData.primaryData.primaryApplicant ? _c('div', {
    class: _vm.doesSecondaryApplicantExist ? 'border-b border-light-grey pb-6 mb-6' : 'mb-6'
  }, [_c('el-row', {
    staticClass: "mb-child-xs-4",
    attrs: {
      "gutter": 16
    }
  }, [!((_vm$complianceData19 = _vm.complianceData) !== null && _vm$complianceData19 !== void 0 && (_vm$complianceData19$ = _vm$complianceData19.sharedData) !== null && _vm$complianceData19$ !== void 0 && (_vm$complianceData19$2 = _vm$complianceData19$.loanDetails) !== null && _vm$complianceData19$2 !== void 0 && (_vm$complianceData19$3 = _vm$complianceData19$2.submission) !== null && _vm$complianceData19$3 !== void 0 && _vm$complianceData19$3.submittedAt) ? _c('el-col', {
    attrs: {
      "lg": 24
    }
  }, [_c('div', {
    staticClass: "mb-6 w-auto inline-flex alertMessage el-alert el-alert--primary text-black is-light warning-address-details",
    attrs: {
      "role": "alert",
      "data-selector": "cpd-loan-details-alert"
    }
  }, [_c('img', {
    staticClass: "warning-address-details-img",
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title",
    attrs: {
      "data-selector": "cpd-loan-details-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_INFO_LOAN_DETAILS))])])])]) : _vm._e(), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "md": 6,
      "xl": 3
    }
  }, [_vm.complianceData.primaryData && _vm.complianceData.primaryData.primaryApplicant && _vm.complianceData.primaryData.primaryApplicant.firstName ? _c('p', {
    staticClass: "mb-2 text-xs font-extrabold",
    attrs: {
      "data-selector": "cpd-p-applicant-name"
    }
  }, [_vm._v(_vm._s(_vm.complianceData.primaryData.primaryApplicant.firstName) + "\n              " + _vm._s(_vm.complianceData.primaryData.primaryApplicant.lastName))]) : _vm._e(), _vm._v(" "), _vm.complianceData.primaryData.primaryApplicant ? _c('p', {
    staticClass: "text-xs",
    attrs: {
      "data-selector": "cpd-p-applicant-type"
    }
  }, [_vm._v("\n              Primary applicant\n            ")]) : _vm._e()]), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "md": 6,
      "xl": 4
    }
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_SENT_AT))]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mb-2 compliance-documents-label"
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_DATE) + "\n              "), _c('span', {
    staticClass: "compliance-documents-value",
    attrs: {
      "data-selector": "cpd-p-sent-at-date"
    }
  }, [_vm._v(_vm._s((_vm$complianceData20 = _vm.complianceData) !== null && _vm$complianceData20 !== void 0 && (_vm$complianceData20$ = _vm$complianceData20.sharedData) !== null && _vm$complianceData20$ !== void 0 && (_vm$complianceData20$2 = _vm$complianceData20$.loanDetails) !== null && _vm$complianceData20$2 !== void 0 && _vm$complianceData20$2.creditProposalDisclosureSentAt ? _vm.format(new Date(_vm.complianceData.sharedData.loanDetails.creditProposalDisclosureSentAt), 'dd/MM/yyyy hh:mm aaa') : '--'))])]), _vm._v(" "), _c('p', {
    staticClass: "text-xs compliance-documents-label"
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_EMAIL) + "\n              "), _c('span', {
    staticClass: "compliance-documents-value",
    attrs: {
      "data-selector": "cpd-p-email"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.complianceData.primaryData.primaryApplicant.email ? _vm.complianceData.primaryData.primaryApplicant.email : '--'))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "md": 6,
      "xl": 4
    }
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_VIEWED_AT))]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mb-2 compliance-documents-label"
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_DATE) + "\n              "), _c('span', {
    staticClass: "compliance-documents-value",
    attrs: {
      "data-selector": "cpd-p-viewed-at"
    }
  }, [_vm._v("--")])])])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.doesSecondaryApplicantExist ? _vm._l(_vm.complianceData.secondaryData, function (otherApplicant, index) {
    var _vm$complianceData21, _vm$complianceData21$, _vm$complianceData21$2;

    return _c('el-row', {
      key: index,
      class: index !== _vm.complianceData.secondaryData.length - 1 ? 'border-b border-light-grey mb-6 pb-6' : '',
      attrs: {
        "gutter": 16
      }
    }, [_c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 12,
        "md": 6,
        "xl": 3
      }
    }, [_c('p', {
      staticClass: "mb-2 text-xs font-extrabold",
      attrs: {
        "data-selector": 'cpd-s-applicant-name_' + index
      }
    }, [_vm._v(_vm._s(_vm.complianceData.secondaryData[index].applicant.firstName) + "\n              " + _vm._s(_vm.complianceData.secondaryData[index].applicant.lastName))]), _vm._v(" "), _vm.complianceData.secondaryData[index].applicant ? _c('p', {
      staticClass: "text-xs",
      attrs: {
        "data-selector": 'cpd-s-applicant-type_' + index
      }
    }, [_vm._v("\n              " + _vm._s(_vm.isCommercial ? _vm.complianceData.secondaryData[index].designation : 'Secondary applicant') + "\n            ")]) : _vm._e()]), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 12,
        "md": 6,
        "xl": 4
      }
    }, [_c('p', {
      staticClass: "mb-2 text-xs font-extrabold"
    }, [_vm._v("Last sent at")]), _vm._v(" "), _c('p', {
      staticClass: "text-xs mb-2 compliance-documents-label"
    }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_DATE) + "\n              "), _c('span', {
      staticClass: "compliance-documents-value",
      attrs: {
        "data-selector": 'cpd-s-sent-at-date_' + index
      }
    }, [_vm._v(_vm._s((_vm$complianceData21 = _vm.complianceData) !== null && _vm$complianceData21 !== void 0 && (_vm$complianceData21$ = _vm$complianceData21.sharedData) !== null && _vm$complianceData21$ !== void 0 && (_vm$complianceData21$2 = _vm$complianceData21$.loanDetails) !== null && _vm$complianceData21$2 !== void 0 && _vm$complianceData21$2.creditProposalDisclosureSentAt ? _vm.format(new Date(_vm.complianceData.sharedData.loanDetails.creditProposalDisclosureSentAt), 'dd/MM/yyyy hh:mm aaa') : '--'))])]), _vm._v(" "), _c('p', {
      staticClass: "text-xs compliance-documents-label"
    }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_EMAIL) + "\n              "), _c('span', {
      staticClass: "compliance-documents-value",
      attrs: {
        "data-selector": 'cpd-s-email_' + index
      }
    }, [_vm._v("\n                " + _vm._s(_vm.complianceData.secondaryData[index].applicant.email ? _vm.complianceData.secondaryData[index].applicant.email : '--'))])])]), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 12,
        "md": 6,
        "xl": 4
      }
    }, [_c('p', {
      staticClass: "mb-2 text-xs font-extrabold"
    }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_VIEWED_AT))]), _vm._v(" "), _c('p', {
      staticClass: "text-xs mb-2 compliance-documents-label"
    }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_DATE) + "\n              "), _c('span', {
      staticClass: "compliance-documents-value",
      attrs: {
        "data-selector": 'cpd-s-viewed-at_' + index
      }
    }, [_vm._v("--")])])])], 1);
  }) : _vm._e()], 2) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('div', {
    staticClass: "border-r border-light-grey mr-6 pr-6"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "comp-back-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.backToPrevious();
      }
    }
  }, [_vm._v("Back")])], 1), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "comp-next-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("Next")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }