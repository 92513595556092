export default {
  getShortId: (state) => state.application.shortId,
  getPrimaryApplicantData: (state) => state.application.primaryApplicantData,
  getOtherApplicants: (state) => state.application.otherApplicants,
  getPrimaryApplicantAddresses: (state) =>
    state.application.primaryApplicantData.addresses,
  getApplication: (state) => state.application,
  getPrimaryApplicant: (state) =>
    state.application.primaryApplicantData.primaryApplicant,
  getIsApplicationLoaded: (state) => state.isLoading,
  getApplicationStatus: (state) => state.application.status,
  getSelectedDocuments: (state) =>
    state.application.primaryApplicantData.selectedDocuments,
  getSharedApplicantData: (state) => state.application,
  getVehicleSourced: (state) => state.vehicleSourced,
  getLeadSource: (state) => state.leadSource,
  getLeadSourceSystem: (state) => state.leadSourceSystem,
  getWebAppJourney: (state) => state.webAppJourney,
  getReferrerAccount: (state) => state.referrerAccount,
  getPartnerId: (state) => state.partnerId,
  getSfLead: (state) => state.sFLead,
  getAccounts: (state) => state.accountData,
  getCloneApplication: (state) => state.application,
  getOwner: (state) => state.owner,
  getSFOpportunity: (state) => state?.sFOpportunity,
  getLinks: (state) => state?.links,
  getInitialLeadFunnel: (state) => state.initialLeadFunnel,
  getTotalNetSurplus: (state) => state.totalNetSurplus,
  getMatchesGenerated: (state) => state.matchesGenerated,
  getGeneratedMatchesData: (state) => {
    return state.generatedMatchesData
  },
  getLenderMatchesSettings: (state) => state.lenderMatchesSettings,
  getLenderMatchesApplicationMatchesForm: (state) =>
    state.lenderMatchesApplicationMatchesForm,
}
