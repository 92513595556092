//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  INSURANCE_FREQUENCY,
  LABEL_TOTAL_FREQUENCY_MONTH,
  LABEL_FOR_FREQUENCY,
  LABEL_MONTHLY_PREMIMUM,
  LABEL_MONTHLY_OPTION_LABEL,
  ERROR_MESSAGE_PLEASE_ENTER_PROVIDER,
  ERROR_MESSAGE_PLEASE_ENTER_POLICY,
  ERROR_MESSAGE_FOR_FREQUENCY_REQUIRED,
  ERROR_MESSAGE_PLEASE_ENTER_MONTHLY_PREMIUM,
  FORM_TYPE_ADD,
  FORM_TYPE_EDIT,
  LABEL_SAVE,
} from '@/constants'

import {
  LABEL_FOR_INSURANCE,
  LABEL_FOR_CANCEL_BUTTON,
  LABEL_FOR_PROVIDER,
  LABEL_FOR_POLICY,
  LABEL_FOR_PREMIUM,
  LABEL_FOR_COMMISSION,
  LABEL_UPDATE,
  LABEL_REMOVE,
  LABEL_FOR_ADD_INSURANCE,
} from '~/constants/applications'
import { axiosErrorMessageExtractor } from '~/utils/error'
import { validatePositiveNumber } from '~/helpers/validators'
import { datadogRum } from '@datadog/browser-rum'

export default {
  name: 'RequestAssetDetails',
  props: {
    openDialog: {
      type: String,
      default: '',
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    /* eslint-disable */
    const checkNumberValue = (_rule, value, callback) =>
      validatePositiveNumber(value, callback)
    /* eslint-enable */
    return {
      LABEL_FOR_INSURANCE,
      LABEL_FOR_CANCEL_BUTTON,
      LABEL_FOR_PROVIDER,
      LABEL_FOR_POLICY,
      LABEL_FOR_PREMIUM,
      LABEL_FOR_COMMISSION,
      LABEL_UPDATE,
      LABEL_SAVE,
      LABEL_REMOVE,
      LABEL_MONTHLY_PREMIMUM,
      LABEL_MONTHLY_OPTION_LABEL,
      LABEL_FOR_FREQUENCY,
      LABEL_TOTAL_FREQUENCY_MONTH,
      INSURANCE_FREQUENCY,
      PROVIDERS: [],
      insuranceData: { ...this.sharedData },
      ERROR_MESSAGE_PLEASE_ENTER_PROVIDER,
      ERROR_MESSAGE_PLEASE_ENTER_POLICY,
      ERROR_MESSAGE_FOR_FREQUENCY_REQUIRED,
      ERROR_MESSAGE_PLEASE_ENTER_MONTHLY_PREMIUM,
      FORM_TYPE_ADD,
      FORM_TYPE_EDIT,
      LABEL_FOR_ADD_INSURANCE,
      rules: {
        'loanDetails.settlement.insurance.provider': [
          {
            required: true,
            message: ERROR_MESSAGE_PLEASE_ENTER_PROVIDER,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.insurance.policyInfo': [
          {
            required: true,
            message: ERROR_MESSAGE_PLEASE_ENTER_POLICY,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.insurance.premium': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.insurance.commision': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.insurance.frequency': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_FREQUENCY_REQUIRED,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.insurance.frequencyAmount': [
          {
            required: true,
            message: ERROR_MESSAGE_PLEASE_ENTER_MONTHLY_PREMIUM,
            trigger: ['blur', 'change'],
          },
        ],
      },
      getIsDialogLoading: false,
    }
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },

  computed: {
    shouldShowFrequencyAmount() {
      return (
        this.insuranceData.loanDetails?.settlement?.insurance?.frequency ===
        LABEL_MONTHLY_OPTION_LABEL
      )
    },
    shouldDisableInsurancePremium() {
      return (
        !this.insuranceData.loanDetails?.settlement?.insurance?.frequency ||
        this.shouldShowFrequencyAmount
      )
    },
    loanDetailsForm() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.insuranceData = JSON.parse(JSON.stringify(this.sharedData))
      return { ...this.sharedData }
    },
  },
  watch: {
    async openDialog() {
      const response = await this.$store.dispatch(
        `applications/getAllInsuranceProviders`
      )
      this.PROVIDERS = response.data
    },
  },
  methods: {
    closeDialog() {
      this.$nuxt.$emit('closeRequestInsuranceDialog')
    },
    async submitForm(removeData = false) {
      try {
        const { id, applicationId } = this.$route.params
        let isValid = false
        this.$refs.insuranceData.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          return
        }

        this.getIsDialogLoading = true
        if (removeData) {
          this.insuranceData.loanDetails.settlement.insurance = {
            provider: '',
            policyInfo: '',
            premium: '',
            commision: '',
          }
        }
        const previousSuccess = Boolean(
          this.insuranceData?.tpInsuranceInvoiceId
        )
        await this.$store.dispatch('applications/updateInsurance', {
          insuranceDetails: { ...this.insuranceData },
          id,
          applicationId,
        })
        if (!removeData) {
          if (
            this.insuranceData?.tpInsuranceInvoiceErrorReason ===
            'Invalid lender info'
          ) {
            datadogRum.addAction('InsuranceInvoiceFailed')
            setTimeout(() => {
              this.$notify({
                title: 'Error',
                message: `Unable to create the insurance invoice as provider ${this.insuranceData?.loanDetails?.settlement?.insurance?.providerData?.businessName} not found. Please check with support and try again.`,
                type: 'error',
                duration: 5000,
              })
            }, 2000)
          } else if (
            this.insuranceData?.tpInsuranceInvoiceErrorReason?.includes(
              'Duplicate Invoice #,'
            )
          ) {
            datadogRum.addAction('InsuranceInvoiceFailed')
            const invoiceNumber =
              this.insuranceData?.tpInsuranceInvoiceErrorReason.match(
                /Duplicate Invoice #, ([\w-]+)/
              )[1]
            setTimeout(() => {
              this.$notify({
                title: 'Error',
                message: `Invoice ${invoiceNumber} already exists. Please check with support for more details.`,
                type: 'error',
                duration: 5000,
              })
            }, 2000)
          } else if (
            this.insuranceData?.tpInsuranceInvoiceErrorReason?.includes(
              'No insurance commission'
            )
          ) {
            datadogRum.addAction('InsuranceInvoiceFailed')
            setTimeout(() => {
              this.$notify({
                title: 'Error',
                message: `Insurance invoice is not created because commission is zero. Please contact support for any further inquiry.`,
                type: 'error',
                duration: 5000,
              })
            }, 2000)
          } else if (this.insuranceData?.tpInsuranceInvoiceErrorReason) {
            datadogRum.addAction('InsuranceInvoiceFailed')
            setTimeout(() => {
              this.$notify({
                title: 'Error',
                message:
                  'Unable to create the insurance invoice. Please check with support and try again.',
                type: 'error',
                duration: 5000,
              })
            }, 2000)
          } else if (
            this.insuranceData?.tpInsuranceInvoiceId &&
            !previousSuccess
          ) {
            datadogRum.addAction('InsuranceInvoiceSuccessPostSettlement')
            setTimeout(() => {
              this.$notify.success({
                title: 'Success',
                message: 'Insurance Invoice created successfully.',
              })
            }, 2000)
          }
        }
        this.$nuxt.$emit('closeRequestInsuranceDialog')
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.getIsDialogLoading = false
      }
    },
    calculatePremimum() {
      this.insuranceData.loanDetails.settlement.insurance.premium =
        this.insuranceData.loanDetails.settlement.insurance.frequencyAmount *
        LABEL_TOTAL_FREQUENCY_MONTH
    },
  },
}
