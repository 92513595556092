//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MissingFieldsModal from './UtilComponents/MissingFieldsModal.vue'
import { changeRoute } from '~/helpers/changeRoute'
import { getBalloonAmountWithApi } from '@/helpers/calculateBalloonAmount'
import { getBalloonPctWithApi } from '@/helpers/calculateBalloonPct'
import {
  ERROR_MESSAGE_FOR_PURCHASE_PRICE,
  ERROR_MESSAGE_FOR_DEPOSIT,
  ERROR_MESSAGE_FOR_TRADE_IN,
  ERROR_MESSAGE_FOR_LOAN_TERM,
  ERROR_MESSAGE_FOR_BALLOON,
  ERROR_MESSAGE_FOR_BALLOON_ONLY,
  ERROR_MESSAGE_FOR_BUDGET_MONTHLY_REPAYMENT,
  ERROR_MESSAGE_FOR_NUMBER_ONLY,
  ERROR_MESSAGE_FOR_INVALID,
  ERROR_MESSAGE_FOR_REQUIRED_FIELD,
  ERROR_MESSAGE_FOR_LENDER_NAME,
  ERROR_MESSAGE_BASE_RATE,
  ERROR_MESSAGE_MONTHLY_REPAYMENT,
  ERROR_MESSAGE_CUSTOMER_RATE,
  ERROR_MESSAGE_ACCOUNT_KEEPING_FEE,
  ERROR_MESSAGE_LENDER_ESTABLISHMENT_FEE,
  ERROR_MESSAGE_PSPR_FEE,
  ERROR_MESSAGE_BROKER_FEE,
  ERROR_MESSAGE_PARTNER_FINANCE_REFERRAL_FEE,
  ERROR_MESSAGE_LENDING_COMMISION_FEE,
  ERROR_MESSAGE_DOES_APPLICANT_AGRRE_WITH_PROPOSED_LOAN_DETAILS,
  ERROR_MESSAGE_DOES_SATISFY_CONSUMERS_OBJECTIVES_AND_REQUIREMENT,
  ERROR_MESSAGE_DOES_APPLICANT_COMPLY_WITH_FINANCIAL_OBLIGATIONS,
  ERROR_MESSAGE_DOES_FUTURE_CHANGES_IMPACT_PROPOSED_LOAN,
  ERROR_MESSAGE_HOW_DOES_SELECTED_LENDER_MEET_CUSTOMERS_NEED,
  ERROR_MESSAGE_REASON_FOR_BALLOON_PAYMENT,
  APPLICATION_STATUS_SETTLED,
  LABEL_VIEW_DETAILS,
  CREDIT_REPORT_PRIVACY_CREDIT_GUIDE,
  LABEL_FOR_CREDIT_GUIDE,
  LABEL_FOR_PRIVACY_FORM,
  LABEL_FOR_PRIVACY_GUIDE,
  LABEL_FOR_CREDIT_QUOTE,
  LABEL_FOR_DOCUSIGN_CERT,
  LABEL_FOR_QUOTE,
  LABEL_CONSUMER,
  COMPLIANCE_DOCS_DROPDOWN_MAPPER,
} from '@/constants'

import { format } from 'date-fns'
import {
  REQUESTED_LOAN_DETAILS,
  LABEL_DOCUMENT_LOAN_TYPE,
  LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
  LABEL_DOCUMENT_DEPOSIT,
  LABEL_TRADE_IN_EQUITY,
  LABEL_DOCUMENT_LOAN_AMOUNT,
  LABEL_LOAN_TREM,
  LABEL_DOCUMENT_BALLOON,
  LABEL_DOCUMENT_BUDGETED_MONTHLY_REPAYMENT,
  LABEL_FOR_CANCEL_BUTTON,
  APPLICANT_PREFIXES_OPTIONS,
  LABEL_RATE_AND_FEE,
  LABEL_BASE_RATE,
  LABEL_MONTHLY_REPAYMENT_AMOUNT,
  LABEL_CUSTOMER_RATE,
  LABEL_LENDER_MONTHLY_FEE,
  LABEL_LENDER_FEE,
  LABEL_PSPR_FEE,
  LABEL_ORIGINATION_FEE,
  LABEL_PARTNER_REFERRAL_FEE,
  LABEL_LENDING_COMMISSION,
  LABEL_QUESTIONS,
  LABEL_IS_THE_CUSTOMER_AGREEABLE,
  LABEL_DOES_THE_PROPOSED_LOAN_SATISFY,
  LABEL_WILL_THE_COSTOMER_BE_ABLE_TO_COMPLY,
  LABEL_DO_YOU_AGREE_THRERE_ARE_NO_KNOWN_OR_LIKELY,
  LABEL_DESCRIBE_HOW_THE_SELECTED_LENDER_AND_PRODUCT_MEET,
  LABEL_SAVE_DETAILS_AND_GENERATE_ASSETMENT,
  LABEL_LENDER,
  LABEL_LOAN_DETAILS,
  LABEL_PRELIMINARY_ASSESSMENT,
  LABEL_PRELIMINARY_ASSESSMENT_DETAILS,
  LABEL_EDIT_SUBMISSION_DETAILS,
  LABEL_VIEW_ASSESSMENT,
  LABEL_NEXT_ACTIONS,
  LABEL_CREDIT_PROPOSAL_DISCLOSURE,
  LABEL_CREDIT_PROPOSAL_DISCLOSURE_DETAILS,
  LABEL_SEND_PROPOSAL,
  LABEL_APPLICATION_SUBMISSION,
  LABEL_SUBMIT_APPLICATION,
  LABEL_APPLICATION_SUBMISSION_DETAILS,
  LABEL_SEND_PROPOSAL_DETAILS,
  LABEL_EDIT_SUBMISSION_DETAILS_SUBTITLE,
  LABEL_SUBMIT_APPLICATION_DETAILS,
  LABEL_LOAN_BALLOON_DOLLAR,
  LABEL_LOAN_SEND_PROPSAL,
  LABEL_LOAN_EDIT_SUBMISSION_DETAILS,
  LABEL_LOAN_SUBMIT_APPLICATION,
  LOAN_TERM_YEAR_OPTIONS,
  LABEL_NOT_AVAILABLE,
  REASON_FOR_BALLOON_PAYMENT_OPTIONS,
  LABEL_NAF,
  LABEL_REASON_FOR_BALLOON_PAYMENT,
  LABEL_OTHER_REASON_FOR_BALLOON_PAYMENT_VIEW,
  LABEL_BALLOON_REPAYMENT_OTHER,
  PLACEHOLDER_FOR_ASSET_DESCRIPTION,
  LABEL_CREDIT_PROPOSAL_DISCLOSURE_SENT_AT,
  LABEL_VIEW_PROPOSAL,
  LABEL_FOR_VIEW,
  LABEL_APPLICATION_SUBMITTED_AT,
  APPLICATION_STATUS_SUBMITTED_TO_LENDER,
  APPLICATION_TRADE_IN_VEHICLE,
  LABEL_HAVE_YOU_MADE_THE_CUSTOMER_AWARE,
  APPLICATION_STATUS_STARTED,
  LABEL_YEARS,
  LABEL_LOWER_MONTHLY_PAYMENT_VAL,
  LABEL_LOWER_MONTHLY_PAYMENT,
  LABEL_YES,
  LABEL_NO,
  LABEL_CONFIRM_EDIT_SUBMISSION_DETAILS,
  LABEL_TRADING_LIABILITIES,
  LABEL_NEGATIVE_NET_SURPLUS_VALUE,
  REGEX_FOR_NET_SURPLUS,
  LABEL_REVIEW_AND_SEND_PROPOSAL,
  LABEL_SEND,
  LABEL_FOR_DOWNLOAD_APPLICATION,
  LABEL_CONFIRM_EDIT_SUBMISSION_DETAILS_COMMERCIAL,
  LABEL_SAVE_DETAILS,
  LABEL_SUBMISSION_DETAILS_SAVED,
  LABEL_SUBMISSION_DETAILS_SAVED_DESCRIPTION,
  LABEL_TITLE_FOR_MISSING_FIELDS_MODEL,
  LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_FIRST,
  LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_SECOND,
  APPLICATION_STATUS_APPROVED,
  APPLICATION_STATUS_WITHDRAWN,
  APPLICATION_STATUS_DECLINED,
  LABEL_SUBMIT_DIGITALLY,
  LABEL_SUBMIT_MANUALLY,
  LABEL_SUBMIT_APPLICATION_DIGITALLY,
  LABEL_SUBMIT_APPLICATION_DIGITALLY_DESCRIPTION,
  LABEL_SUBMIT_APPLICATION_MANUALLY,
  LABEL_SUBMIT_APPLICATION_MANUALLY_DESCRIPTION,
  LABEL_APPLICATION_SUBMITTED_MANUALLY,
  LABEL_APPLICATION_SUBMITTED_DIGITALLY,
  LABEL_FOR_VIEW_IN_LENDER_PORTAL,
  LABEL_MANUAL,
  LABEL_DIGITAL,
  LABEL_ALLOWED_DIGITAL_SUBMISSION_LENDERS,
  LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_DISCRIPTION,
  LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_TITLE,
  LABEL_LENDING_COMMISSION_FEE,
  LABEL_APPLICATION_CANNOT_BE_SUBMITTED,
  LABEL_APPLICATION_CANNOT_BE_SUBMITTED_DESCRIPTION,
  LABEL_NO_OF_CONTACTS_MISMATCH,
  LABEL_BUSINESS_STRUCTURE_COMPANY,
  LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
  LABEL_CONTACTS_MISMATCH_DISCRIPTION_FOR_DIRECTORS,
  LABEL_CONTACTS_MISMATCH_DISCRIPTION_FOR_PARTNERS,
  LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY,
  LABEL_BUSINESS_STRUCTURE_TRUST,
  LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SENT,
  LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SENT_DISCRIPTION,
  LABEL_FOR_DOCUMENTS_ARE_SIGNED_AT,
  LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SIGNED,
  LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SIGNED_DISCRIPTION,
  SUBTAB_PRIMARY_PERSONAL_KEY,
  LABEL_FOR_PRIVACY_FROM_IS_NOT_SIGNED,
  LABEL_FOR_PRIVACY_FROM_IS_NOT_SENT_DISCRIPTION,
  LABEL_FOR_PRIVACY_FROM_IS_NOT_SIGNED_DISCRIPTION,
  LABEL_FOR_PRIVACY_FROM_IS_NOT_SENT,
  LABEL_FOR_SUBMIT_APPLICATION_DETAILS,
  LABEL_FOR_DOCUMENT_SIGNED_AT_COMMERCIAL,
  COMPLIANCE_DOCS_PURPOSES,
} from '@/constants/applications'
import { twoDecimalDigitsOrNone } from '~/helpers/decimalFormat'
import { toCurrency } from '@/helpers/currency'
import { tradeInLiabilityCommon } from '@/helpers/tradeInLiabilityCommon'
import {
  validatePositiveNumber,
  validateBalloonPercentage,
  validateAmountShouldGreaterThanZero,
  checkAmount,
  validatePercentageWithGreaterThanZero,
  validateBrokerFee,
  validatePositiveNumberWithZero,
} from '../helpers/validators'
import LoanSubmissionDialog from './LoanSubmissionDialog.vue'
import EditSubmissionConfirmDialog from './EditSubmissionConfirmDialog.vue'
import { axiosErrorMessageExtractor } from '~/utils/error'
import { scrollToElement } from '~/utils/scroll'
import AlertDialog from './UtilComponents/AlertDialog.vue'
import ZeroConfirmationDialog from './UtilComponents/ZeroConfirmationDialog.vue'
import LoanSubmissionError from './UtilComponents/LoanSubmissionError.vue'
import { getSignedURL } from '~/utils/url'
import { mapGetters } from 'vuex'
import PrivacyFormHeader from './UtilComponents/PrivacyFormHeader.vue'
export default {
  name: 'LoanDetails',
  components: {
    MissingFieldsModal,
    LoanSubmissionDialog,
    EditSubmissionConfirmDialog,
    AlertDialog,
    LoanSubmissionError,
    ZeroConfirmationDialog,
    PrivacyFormHeader,
  },
  props: {
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    accountData: {
      type: Array,
      default: () => [],
    },
    isConsumer: {
      type: Boolean,
      default: false,
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const checkPurchasePrice = (_rule, value, callback) =>
      checkAmount(_rule, value, callback)
    const checkFinanceDepositAmount = (_rule, value, callback) =>
      checkAmount(
        _rule,
        value,
        callback,
        this.loanData.appData.loanDetails.submission.purchasePrice
      )

    const checkNumberValue = (_rule, value, callback) =>
      validatePositiveNumber(value, callback)

    const checkNumberShouldBeGreaterThanZero = (_rule, value, callback) =>
      validateAmountShouldGreaterThanZero(value, callback)

    const checkNumberWithZero = (_rule, value, callback) =>
      validatePositiveNumberWithZero(value, callback)

    return {
      LABEL_CONSUMER,
      LABEL_FOR_PRIVACY_FROM_IS_NOT_SENT,
      LABEL_FOR_PRIVACY_FROM_IS_NOT_SIGNED,
      LABEL_FOR_PRIVACY_FROM_IS_NOT_SENT_DISCRIPTION,
      LABEL_FOR_PRIVACY_FROM_IS_NOT_SIGNED_DISCRIPTION,
      LABEL_FOR_CREDIT_QUOTE,
      LABEL_FOR_QUOTE,
      LABEL_FOR_CREDIT_GUIDE,
      LABEL_FOR_PRIVACY_FORM,
      LABEL_FOR_PRIVACY_GUIDE,
      LABEL_FOR_DOCUSIGN_CERT,
      SUBTAB_PRIMARY_PERSONAL_KEY,
      allZeroValueFieldsData: [],
      APPLICATION_STATUS_APPROVED,
      APPLICATION_STATUS_WITHDRAWN,
      APPLICATION_STATUS_DECLINED,
      APPLICATION_STATUS_SETTLED,
      LABEL_TITLE_FOR_MISSING_FIELDS_MODEL,
      LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_FIRST,
      LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_SECOND,
      openMissingFieldsModal: false,
      errorsdata: '',
      LABEL_REVIEW_AND_SEND_PROPOSAL,
      LABEL_FOR_VIEW,
      ERROR_MESSAGE_FOR_PURCHASE_PRICE,
      ERROR_MESSAGE_FOR_DEPOSIT,
      ERROR_MESSAGE_FOR_TRADE_IN,
      ERROR_MESSAGE_FOR_LOAN_TERM,
      ERROR_MESSAGE_FOR_BALLOON,
      ERROR_MESSAGE_FOR_BALLOON_ONLY,
      ERROR_MESSAGE_FOR_BUDGET_MONTHLY_REPAYMENT,
      ERROR_MESSAGE_FOR_NUMBER_ONLY,
      ERROR_MESSAGE_BASE_RATE,
      ERROR_MESSAGE_MONTHLY_REPAYMENT,
      ERROR_MESSAGE_CUSTOMER_RATE,
      ERROR_MESSAGE_ACCOUNT_KEEPING_FEE,
      ERROR_MESSAGE_LENDER_ESTABLISHMENT_FEE,
      ERROR_MESSAGE_PSPR_FEE,
      ERROR_MESSAGE_BROKER_FEE,
      ERROR_MESSAGE_PARTNER_FINANCE_REFERRAL_FEE,
      ERROR_MESSAGE_LENDING_COMMISION_FEE,
      ERROR_MESSAGE_DOES_APPLICANT_AGRRE_WITH_PROPOSED_LOAN_DETAILS,
      ERROR_MESSAGE_DOES_SATISFY_CONSUMERS_OBJECTIVES_AND_REQUIREMENT,
      ERROR_MESSAGE_DOES_APPLICANT_COMPLY_WITH_FINANCIAL_OBLIGATIONS,
      ERROR_MESSAGE_DOES_FUTURE_CHANGES_IMPACT_PROPOSED_LOAN,
      ERROR_MESSAGE_HOW_DOES_SELECTED_LENDER_MEET_CUSTOMERS_NEED,
      ERROR_MESSAGE_REASON_FOR_BALLOON_PAYMENT,
      REQUESTED_LOAN_DETAILS,
      LABEL_DOCUMENT_LOAN_TYPE,
      LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
      LABEL_DOCUMENT_DEPOSIT,
      LABEL_TRADE_IN_EQUITY,
      LABEL_DOCUMENT_LOAN_AMOUNT,
      LABEL_LOAN_TREM,
      ERROR_MESSAGE_FOR_INVALID,
      LABEL_DOCUMENT_BALLOON,
      LABEL_DOCUMENT_BUDGETED_MONTHLY_REPAYMENT,
      LABEL_FOR_CANCEL_BUTTON,
      APPLICANT_PREFIXES_OPTIONS,
      LABEL_RATE_AND_FEE,
      LABEL_BASE_RATE,
      LABEL_MONTHLY_REPAYMENT_AMOUNT,
      LABEL_CUSTOMER_RATE,
      LABEL_LENDER_MONTHLY_FEE,
      LABEL_NOT_AVAILABLE,
      LABEL_LENDER_FEE,
      LABEL_PSPR_FEE,
      LABEL_ORIGINATION_FEE,
      LABEL_PARTNER_REFERRAL_FEE,
      LABEL_LENDING_COMMISSION,
      LABEL_QUESTIONS,
      LABEL_IS_THE_CUSTOMER_AGREEABLE,
      LABEL_DOES_THE_PROPOSED_LOAN_SATISFY,
      LABEL_WILL_THE_COSTOMER_BE_ABLE_TO_COMPLY,
      LABEL_DO_YOU_AGREE_THRERE_ARE_NO_KNOWN_OR_LIKELY,
      LABEL_DESCRIBE_HOW_THE_SELECTED_LENDER_AND_PRODUCT_MEET,
      LABEL_SAVE_DETAILS_AND_GENERATE_ASSETMENT,
      LABEL_SAVE_DETAILS,
      LABEL_LENDER,
      LABEL_LOAN_DETAILS,
      LABEL_PRELIMINARY_ASSESSMENT,
      LABEL_PRELIMINARY_ASSESSMENT_DETAILS,
      LABEL_EDIT_SUBMISSION_DETAILS,
      LABEL_VIEW_ASSESSMENT,
      LABEL_NEXT_ACTIONS,
      LABEL_CREDIT_PROPOSAL_DISCLOSURE,
      LABEL_CREDIT_PROPOSAL_DISCLOSURE_DETAILS,
      LABEL_SEND_PROPOSAL,
      LABEL_APPLICATION_SUBMISSION,
      LABEL_SUBMIT_APPLICATION,
      LABEL_APPLICATION_SUBMISSION_DETAILS,
      LABEL_SEND_PROPOSAL_DETAILS,
      LABEL_CONFIRM_EDIT_SUBMISSION_DETAILS,
      LABEL_CONFIRM_EDIT_SUBMISSION_DETAILS_COMMERCIAL,
      LABEL_SUBMISSION_DETAILS_SAVED,
      LABEL_SUBMISSION_DETAILS_SAVED_DESCRIPTION,
      LABEL_EDIT_SUBMISSION_DETAILS_SUBTITLE,
      LABEL_SUBMIT_APPLICATION_DETAILS,
      LABEL_LOAN_SEND_PROPSAL,
      LABEL_LOAN_EDIT_SUBMISSION_DETAILS,
      LABEL_LOAN_SUBMIT_APPLICATION,
      LABEL_NEGATIVE_NET_SURPLUS_VALUE,
      ERROR_MESSAGE_FOR_REQUIRED_FIELD,
      LABEL_LOAN_BALLOON_DOLLAR,
      ERROR_MESSAGE_FOR_LENDER_NAME,
      LOAN_TERM_YEAR_OPTIONS,
      REASON_FOR_BALLOON_PAYMENT_OPTIONS,
      LABEL_NAF,
      LABEL_REASON_FOR_BALLOON_PAYMENT,
      LABEL_OTHER_REASON_FOR_BALLOON_PAYMENT_VIEW,
      LABEL_BALLOON_REPAYMENT_OTHER,
      PLACEHOLDER_FOR_ASSET_DESCRIPTION,
      LABEL_CREDIT_PROPOSAL_DISCLOSURE_SENT_AT,
      LABEL_VIEW_PROPOSAL,
      LABEL_APPLICATION_SUBMITTED_AT,
      APPLICATION_STATUS_SUBMITTED_TO_LENDER,
      APPLICATION_TRADE_IN_VEHICLE,
      LABEL_HAVE_YOU_MADE_THE_CUSTOMER_AWARE,
      LABEL_LOWER_MONTHLY_PAYMENT_VAL,
      LABEL_LOWER_MONTHLY_PAYMENT,
      APPLICATION_STATUS_STARTED,
      REGEX_FOR_NET_SURPLUS,
      LABEL_YES,
      LABEL_NO,
      LABEL_YEARS,
      LABEL_TRADING_LIABILITIES,
      LABEL_SEND,
      LABEL_FOR_DOWNLOAD_APPLICATION,
      LABEL_APPLICATION_CANNOT_BE_SUBMITTED,
      LABEL_APPLICATION_CANNOT_BE_SUBMITTED_DESCRIPTION,
      shouldIncludeComplianceQuestions: true,
      format,
      selectedAccount: {
        businessName: '',
      },
      dialogSendProposal: false,
      dialogViewProposal: false,
      dialogSubmitApplication: false,
      dialogEditSubmissionDetails: false,
      remainingLiability: 0,
      rules: {
        'appData.loanDetails.submission.purchasePrice': [
          {
            required: true,
            validator: checkPurchasePrice,
            trigger: ['blur', 'change'],
          },
        ],

        'appData.loanDetails.submission.depositAmount': [
          {
            required: true,
            validator: checkFinanceDepositAmount,
            trigger: ['blur', 'change'],
          },
        ],
        'loanData.loanDetails.submission.tradeInAmount': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'appData.loanDetails.submission.loanTermYears': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_LOAN_TERM,
            trigger: ['blur', 'change'],
          },
        ],
        'appData.loanDetails.submission.balloonPct': [
          {
            required: true,
            validator: (_rule, value, callback) => {
              validateBalloonPercentage(
                value,
                callback,
                this.loanData.appData.loanDetails.submission.loanTermYears,
                ERROR_MESSAGE_FOR_BALLOON
              )
            },
            trigger: ['blur', 'change'],
          },
        ],
        'appData.loanDetails.submission.balloonAmount': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'appData.customerReasonForBalloon': [
          {
            required: true,
            message: ERROR_MESSAGE_REASON_FOR_BALLOON_PAYMENT,
            trigger: ['blur', 'change'],
          },
        ],
        'appData.loanDetails.submission.baseRate': [
          {
            required: true,
            validator: (_rule, value, callback) => {
              validatePercentageWithGreaterThanZero(
                value,
                callback,
                ERROR_MESSAGE_BASE_RATE
              )
            },
            trigger: ['blur', 'change'],
          },
        ],
        'appData.loanDetails.submission.customerRate': [
          {
            required: true,
            validator: (_rule, value, callback) => {
              validatePercentageWithGreaterThanZero(
                value,
                callback,
                ERROR_MESSAGE_CUSTOMER_RATE
              )
            },
            trigger: ['blur', 'change'],
          },
        ],
        'appData.loanDetails.submission.monthlyRepayment': [
          {
            required: true,
            validator: checkNumberShouldBeGreaterThanZero,
            trigger: ['blur', 'change'],
          },
        ],
        'appData.loanDetails.submission.accountKeepingFee': [
          {
            required: true,
            validator: checkNumberWithZero,
            trigger: ['blur', 'change'],
          },
        ],
        'appData.loanDetails.submission.lenderEstablishmentFee': [
          {
            required: true,
            validator: checkNumberWithZero,
            trigger: ['blur', 'change'],
          },
        ],
        'appData.loanDetails.submission.ppsrFee': [
          {
            required: true,
            validator: checkNumberWithZero,
            trigger: ['blur', 'change'],
          },
        ],
        'appData.loanDetails.submission.brokerFee': [
          {
            required: true,
            validator: checkNumberWithZero,
            trigger: ['blur', 'change'],
          },
          {
            validator: (_rule, value, callback) => {
              validateBrokerFee(value, callback)
            },
            trigger: ['blur', 'change'],
          },
        ],
        'appData.loanDetails.submission.lendingCommision': [
          {
            required: true,
            validator: checkNumberWithZero,
            trigger: ['blur', 'change'],
          },
        ],
        'appData.loanDetails.submission.netAmountFinanced': [
          {
            required: true,
            validator: checkNumberShouldBeGreaterThanZero,
            trigger: ['blur', 'change'],
          },
        ],
      },
      editMode: true,
      preliminaryAssessmentMsg: false,
      loanData: { ...this.sharedData, ...this.accountData },
      dialogOpenType: null,
      dialogTitle: null,
      dialogSubTitle: null,
      dialogOpened: false,
      dailogDescription: null,
      editSubmissionDialogOpened: false,
      netSurplusDialog: false,
      getIsDialogLoading: false,
      netValue: null,
      isAllowed: false,
      openSendProposalConfirmation: false,
      cpdUrl: null,
      LABEL_SUBMIT_DIGITALLY,
      LABEL_SUBMIT_MANUALLY,
      isApplicationSubmittedDigitally: true,
      LABEL_SUBMIT_APPLICATION_DIGITALLY,
      LABEL_SUBMIT_APPLICATION_DIGITALLY_DESCRIPTION,
      LABEL_SUBMIT_APPLICATION_MANUALLY,
      LABEL_SUBMIT_APPLICATION_MANUALLY_DESCRIPTION,
      LABEL_APPLICATION_SUBMITTED_DIGITALLY,
      LABEL_APPLICATION_SUBMITTED_MANUALLY,
      LABEL_FOR_VIEW_IN_LENDER_PORTAL,
      LABEL_MANUAL,
      LABEL_DIGITAL,
      LABEL_ALLOWED_DIGITAL_SUBMISSION_LENDERS,
      hasLoanSubmissionErr: false,
      loanSubmissionErrors: null,
      LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_DISCRIPTION,
      LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_TITLE,
      openZeroConfirmationDialog: false,
      LABEL_LENDING_COMMISSION_FEE,
      LABEL_NO_OF_CONTACTS_MISMATCH,
      LABEL_BUSINESS_STRUCTURE_COMPANY,
      LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
      LABEL_CONTACTS_MISMATCH_DISCRIPTION_FOR_DIRECTORS,
      LABEL_CONTACTS_MISMATCH_DISCRIPTION_FOR_PARTNERS,
      LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY,
      LABEL_BUSINESS_STRUCTURE_TRUST,
      LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SENT,
      LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SENT_DISCRIPTION,
      LABEL_FOR_DOCUMENTS_ARE_SIGNED_AT,
      LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SIGNED,
      LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SIGNED_DISCRIPTION,
      LABEL_FOR_SUBMIT_APPLICATION_DETAILS,
      LABEL_FOR_DOCUMENT_SIGNED_AT_COMMERCIAL,
      LABEL_VIEW_DETAILS,
      triggerValidation: false,
      customDebounce: null,
      btnLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      totalNetSurplus: 'applications/getTotalNetSurplus',
    }),
    loanSubmissionForm() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loanData = {
        appData: JSON.parse(JSON.stringify(this.sharedData)),
        appAccountData: JSON.parse(JSON.stringify(this.accountData)),
      }

      return { ...this.sharedData, ...this.accountData }
    },
    proposalCreated() {
      let finalURL = null

      if (this.loanData?.appData?.sharedDocuments) {
        this.loanData.appData.sharedDocuments.forEach((doc) => {
          if (
            doc.purpose.toLowerCase() ===
            this.LABEL_CREDIT_PROPOSAL_DISCLOSURE.toLowerCase()
          )
            finalURL = doc.url
        })
      }

      return !!finalURL
    },
    isValidStatus() {
      return !this.proposalCreated && this.isConsumer
        ? true
        : [APPLICATION_STATUS_WITHDRAWN, APPLICATION_STATUS_DECLINED].includes(
            this.loanData?.appData?.status
          )
    },
    isAllowedLender() {
      return LABEL_ALLOWED_DIGITAL_SUBMISSION_LENDERS.includes(
        this.loanData?.appData?.loanDetails?.lenderData?.businessName
      )
    },
    isNetSurplusNegativeOrZero() {
      return (
        this.isConsumer && this.totalNetSurplus && this.totalNetSurplus <= 0
      )
    },
    isSubmissionButtonDisabled() {
      return [
        APPLICATION_STATUS_WITHDRAWN,
        APPLICATION_STATUS_DECLINED,
        APPLICATION_STATUS_SETTLED,
      ].includes(this.loanData?.appData?.status)
    },
    showWarningForContactsMismatch() {
      const { businessData } = this.sharedData
      const applicants = this.loanData?.appData?.otherApplicants.length + 1
      if (
        businessData?.businessStructure === LABEL_BUSINESS_STRUCTURE_PARTNERSHIP
      ) {
        return applicants < businessData?.noOfPartners
      } else if (
        businessData?.businessStructure === LABEL_BUSINESS_STRUCTURE_COMPANY
      ) {
        return applicants < businessData?.noOfDirectors
      } else if (
        businessData?.businessStructure === LABEL_BUSINESS_STRUCTURE_TRUST &&
        businessData?.trusteeType === LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY
      ) {
        return applicants < businessData?.noOfTrusteeDirectors
      } else {
        return false
      }
      return false
    },

    warningMessage() {
      const { businessData } = this.sharedData
      if (
        businessData?.businessStructure === LABEL_BUSINESS_STRUCTURE_PARTNERSHIP
      ) {
        return LABEL_CONTACTS_MISMATCH_DISCRIPTION_FOR_PARTNERS
      } else if (
        businessData?.businessStructure === LABEL_BUSINESS_STRUCTURE_COMPANY ||
        (businessData?.businessStructure === LABEL_BUSINESS_STRUCTURE_TRUST &&
          businessData?.trusteeType === LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY)
      ) {
        return LABEL_CONTACTS_MISMATCH_DISCRIPTION_FOR_DIRECTORS
      }
      return LABEL_CONTACTS_MISMATCH_DISCRIPTION_FOR_DIRECTORS
    },
    isPrivacyDocsAreSent() {
      return (
        !!this.sharedData?.primaryApplicantData?.eSignRequestedAt &&
        (this.sharedData?.otherApplicants.length
          ? !this.sharedData?.otherApplicants?.some((applicant) => {
              return !applicant?.eSignRequestedAt
            })
          : true)
      )
    },
    isPrivacyFormAreSigned() {
      return (
        !!this.sharedData?.primaryApplicantData?.eSignAt &&
        (this.sharedData?.otherApplicants.length
          ? !this.sharedData?.otherApplicants?.some((applicant) => {
              return !applicant?.eSignAt
            })
          : true)
      )
    },
    privacyPolicyCheckTitle() {
      if (this.isConsumer) {
        if (!this.isPrivacyDocsAreSent) {
          return LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SENT
        } else if (!this.isPrivacyFormAreSigned) {
          return LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SIGNED
        } else {
          return CREDIT_REPORT_PRIVACY_CREDIT_GUIDE
        }
      } else {
        if (!this.isPrivacyDocsAreSent) {
          return LABEL_FOR_PRIVACY_FROM_IS_NOT_SENT
        } else if (!this.isPrivacyFormAreSigned) {
          return LABEL_FOR_PRIVACY_FROM_IS_NOT_SIGNED
        } else {
          return LABEL_FOR_PRIVACY_FORM
        }
      }
    },
    privacyPolicyCheckDiscription() {
      if (!this.isConsumer) {
        if (!this.isPrivacyDocsAreSent) {
          return LABEL_FOR_PRIVACY_FROM_IS_NOT_SENT_DISCRIPTION
        } else if (!this.isPrivacyFormAreSigned) {
          return LABEL_FOR_PRIVACY_FROM_IS_NOT_SIGNED_DISCRIPTION
        } else if (this.sharedData?.primaryApplicantData?.eSignAt) {
          return (
            LABEL_FOR_DOCUMENT_SIGNED_AT_COMMERCIAL +
            format(
              new Date(this.sharedData?.primaryApplicantData?.eSignAt),
              'dd/MM/yyyy hh:mm aaa'
            )
          )
        } else {
          return LABEL_FOR_PRIVACY_FROM_IS_NOT_SIGNED_DISCRIPTION
        }
      } else {
        if (!this.isPrivacyDocsAreSent) {
          return LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SENT_DISCRIPTION
        } else if (!this.isPrivacyFormAreSigned) {
          return LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SIGNED_DISCRIPTION
        } else if (this.sharedData?.primaryApplicantData?.eSignAt) {
          return (
            LABEL_FOR_DOCUMENTS_ARE_SIGNED_AT +
            format(
              new Date(this.sharedData?.primaryApplicantData?.eSignAt),
              'dd/MM/yyyy hh:mm aaa'
            )
          )
        } else {
          return LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SIGNED_DISCRIPTION
        }
      }
    },
    getSharedDocuments() {
      if (!this.sharedData?.sharedDocuments) {
        return []
      }
      const filteredDocs = this.sharedData?.sharedDocuments.filter((doc) => {
        return COMPLIANCE_DOCS_PURPOSES.includes(doc.purpose)
      })

      return filteredDocs.map((doc) => {
        return {
          command: COMPLIANCE_DOCS_DROPDOWN_MAPPER[doc.purpose]?.command,
          label: COMPLIANCE_DOCS_DROPDOWN_MAPPER[doc.purpose]?.label,
          disabled: false,
        }
      })
    },
  },
  mounted() {
    this.checkTradeInLiability()
    this.$nuxt.$on('close-loan-tab-dialog', () => (this.dialogOpened = false))
    this.$nuxt.$on(
      'close-Zero-Confirmation-Dialog',
      () => (this.openZeroConfirmationDialog = false)
    )
    this.$nuxt.$on(
      'close-loan-submission-dialog',
      () => (this.editSubmissionDialogOpened = false)
    )
    this.$nuxt.$on('close-alert-dialog', () => (this.netSurplusDialog = false))
    this.$nuxt.$on('alert-submit-event-click', (data) => this.submit(data))
    this.$nuxt.$on(
      'confirm-loan-submission-dialog',
      () => ((this.editMode = true), (this.editSubmissionDialogOpened = false))
    )
    this.$nuxt.$on(
      'close-send-proposal-dialog-submission',
      () => (this.openSendProposalConfirmation = false)
    )
    this.$nuxt.$on('open-missing-fields-modal-submission', (data) =>
      this.openMissingFieldsModalSubmission(data)
    )

    this.$nuxt.$on('set-loan-submission-editmode', () => {
      if (
        this.loanData?.appData?.loanDetails?.creditProposalDisclosureAt ||
        !this.isConsumer
      ) {
        this.editMode = false
        this.preliminaryAssessmentMsg = true
      }
      if (!this.loanData?.appData?.loanDetails?.preliminaryAssessmentAt) {
        this.editMode = true
      }
    })

    if (
      this.loanData?.appData?.loanDetails?.creditProposalDisclosureAt ||
      !this.isConsumer
    ) {
      this.editMode = false
      this.preliminaryAssessmentMsg = true
    }

    if (!this.loanData?.appData?.loanDetails?.preliminaryAssessmentAt) {
      this.editMode = true
    }
    this.$nuxt.$on('show-loan-submission-error', (error) => {
      this.hasLoanSubmissionErr = true
      this.loanSubmissionErrors = error
    })
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
    this.$nuxt.$on('close-loan-submission-error-dialog', () => {
      this.hasLoanSubmissionErr = false
      this.loanSubmissionErrors = null
    })

    setTimeout(() => {
      this.triggerValidation = true
    }, 2000)
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
    this.$nuxt.$off('close-loan-tab-dialog')
    this.$nuxt.$off('close-loan-submission-dialog')
    this.$nuxt.$off('close-alert-dialog')
    this.$nuxt.$off('alert-submit-event-click')
    this.$nuxt.$off('confirm-loan-submission-dialog')
    this.$nuxt.$off('close-send-proposal-dialog-submission')
    this.$nuxt.$off('close-Zero-Confirmation-Dialog')
    this.$nuxt.$off('show-loan-submission-error')
    this.$nuxt.$off('close-loan-submission-error-dialog')
  },
  methods: {
    ...{ twoDecimalDigitsOrNone },
    onInput(field) {
      if (this.triggerValidation) {
        let isValid = false
        this.$refs.loanSubmissionForm.validateField(field, (result) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          return (isValid = result)
        })
      }
    },
    openMissingFieldsModalSubmission(err) {
      this.errorsdata = err
      this.openMissingFieldsModal = !this.openMissingFieldsModal
    },
    closeMissingFieldsModalSubmission() {
      this.openMissingFieldsModal = !this.openMissingFieldsModal
    },
    currencyDecimalDigitFormat(x) {
      return twoDecimalDigitsOrNone(x)
    },
    numberWithCommas(x) {
      return toCurrency(x)
    },
    async calculateBallonAmount() {
      const { submission } = this.loanData.appData.loanDetails

      this.btnLoading = true
      clearTimeout(this.customDebounce)
      this.customDebounce = setTimeout(async () => {
        submission.balloonAmount =
          (await getBalloonAmountWithApi(
            submission?.purchasePrice || 0,
            submission?.balloonPct || 0
          )) || 0
        this.btnLoading = false
      }, 600)
    },
    calculateBallonPct() {
      const { submission } = this.loanData.appData.loanDetails
      this.btnLoading = true
      clearTimeout(this.customDebounce)
      this.customDebounce = setTimeout(async () => {
        submission.balloonPct =
          (await getBalloonPctWithApi(
            submission?.purchasePrice || 0,
            submission?.balloonAmount || 0
          )) || 0
        this.btnLoading = false
      }, 600)
    },

    editSubmissionDetails() {
      this.openEditSubmissionConformation(
        this.LABEL_EDIT_SUBMISSION_DETAILS,
        this.LABEL_EDIT_SUBMISSION_DETAILS,
        this.isConsumer
          ? this.LABEL_CONFIRM_EDIT_SUBMISSION_DETAILS
          : this.LABEL_CONFIRM_EDIT_SUBMISSION_DETAILS_COMMERCIAL
      )
      // this.editMode = true
    },
    openEditSubmissionConformation(type, title, subTitle) {
      this.editSubmissionDialogOpened = true
      this.dialogOpenType = type
      this.dialogTitle = title
      this.dialogSubTitle = subTitle
    },
    viewAssetment(type) {
      let returnURL = null
      this.loanData.appData.sharedDocuments.forEach((doc) => {
        if (doc.purpose.toLowerCase() === type.toLowerCase()) {
          returnURL = doc.url
        }
      })
      if (returnURL) window.open(getSignedURL(returnURL, this.$auth))
    },
    sendProposal() {
      const { id, applicationId } = this.$route.params
      this.$store
        .dispatch('applications/sendProposal', {
          id,
          applicationId,
        })
        .then(() => {
          this.openSendProposalConfirmation = false
        })
    },
    submitApplication() {
      if (this.isSubmissionButtonDisabled) {
        return
      }
      this.openDialog(
        this.LABEL_LOAN_SUBMIT_APPLICATION,
        this.LABEL_SUBMIT_APPLICATION,
        this.LABEL_SUBMIT_APPLICATION_DETAILS
      )
    },
    openDialog(type, title, subTitle) {
      this.dialogOpened = true
      this.dialogOpenType = type
      this.dialogTitle = title
      this.dialogSubTitle = subTitle
    },
    async checkNetSurPlus(shouldIncludeComplianceQuestions) {
      shouldIncludeComplianceQuestions
        ? (this.shouldIncludeComplianceQuestions = true)
        : (this.shouldIncludeComplianceQuestions = false)
      if (this.isConsumer && this.totalNetSurplus < 0) {
        this.dailogDescription = LABEL_NEGATIVE_NET_SURPLUS_VALUE.replace(
          REGEX_FOR_NET_SURPLUS,
          this.numberWithCommas(this.totalNetSurplus)
        )
        this.netSurplusDialog = true
      } else {
        if (!shouldIncludeComplianceQuestions) {
          this.downloadPreliminaryAssessmentPDF()
        } else {
          this.allZeroValueFieldsData = []
          let { brokerFee, lendingCommision, lenderEstablishmentFee } =
            this.loanData?.appData?.loanDetails?.submission

          this.checkZeroVal(brokerFee, LABEL_ORIGINATION_FEE)
          this.checkZeroVal(lendingCommision, LABEL_LENDING_COMMISSION_FEE)
          this.checkZeroVal(lenderEstablishmentFee, LABEL_LENDER_FEE)

          if (this.allZeroValueFieldsData?.length) {
            let isValid = false
            await this.$refs?.loanSubmissionForm?.validate((result) => {
              return (isValid = result)
            })
            if (isValid) {
              this.openZeroConfirmationDialog = true
            }
          } else {
            this.submit()
          }
        }
      }
    },
    checkZeroVal(val, type) {
      if (parseFloat(val) <= 0 || isNaN(parseFloat(val))) {
        this.allZeroValueFieldsData.push(type)
      }
    },
    async downloadPreliminaryAssessmentPDF() {
      try {
        const { id, applicationId } = this.$route.params
        this.getIsDialogLoading = true
        await this.$store.dispatch('applications/downloadApplication', {
          id,
          applicationId,
          shouldIncludeComplianceQuestions:
            this.shouldIncludeComplianceQuestions,
        })
        this.getIsDialogLoading = false
      } catch (err) {
        this.getIsDialogLoading = false
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      }
    },
    redirectToCompliance() {
      this.redirectToPage('compliance')
    },

    async redirectToPage(pagename) {
      if (this.isCommercial) {
        return this.$nuxt.$emit('changeTab', {
          tab: pagename,
          subTab: `primary_${SUBTAB_PRIMARY_PERSONAL_KEY}`,
        })
      }
      const { id, applicationId } = this.$route.params
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: pagename,
        },
      })
      this.$nuxt.$emit('updateComponent', pagename)
    },
    handleDocumentsUrl(purpose) {
      const { sharedDocuments } = this.sharedData
      if (sharedDocuments) {
        let redirectUrl = null
        sharedDocuments.forEach((doc) => {
          if (doc.purpose.toLowerCase() === purpose.toLowerCase())
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            redirectUrl = doc.url
        })
        window.open(getSignedURL(redirectUrl, this.$auth), '_blank')
      }
    },
    async regenerateProposal() {
      try {
        const { id, applicationId } = this.$route.params
        this.getIsDialogLoading = true
        await this.$store.dispatch('applications/regenerateCPDPdf', {
          id,
          applicationId,
        })
      } catch (err) {
        this.getIsDialogLoading = false
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.getIsDialogLoading = false
      }
    },
    reviewAndSendProposal(type) {
      this.cpdUrl = null
      this.openSendProposalConfirmation = true
      let returnURL = null
      this.loanData.appData.sharedDocuments.forEach((doc) => {
        if (doc.purpose.toLowerCase() === type.toLowerCase()) {
          returnURL = doc.url
        }
      })
      this.cpdUrl = getSignedURL(returnURL, this.$auth)
    },
    async submit(data) {
      try {
        const { id, applicationId } = this.$route.params
        let isValid = false
        this.loanData.appData.loanDetails.submission.balloonPct =
          this.loanData.appData.loanDetails.submission.balloonPct || 0
        await this.$refs.loanSubmissionForm.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          const nodes = document.querySelectorAll('.loanSubmission .is-error')
          if (nodes && nodes.length > 0 && nodes[0]) {
            this.$nextTick(() => {
              scrollToElement(nodes[0])
            })
          }
          return
        }
        if (data) {
          this.loanData.appData.loanDetails.submission.zeroValueReason =
            data.zeroValueReason
          this.loanData.appData.loanDetails.submission.zeroValueReasonType =
            data.zeroValueReasonType
        }
        this.getIsDialogLoading = true
        await this.$store.dispatch('applications/updateLoanSubmission', {
          loanDetails: { ...this.loanData.appData },
          id,
          applicationId,
          shouldIncludeComplianceQuestions:
            this.shouldIncludeComplianceQuestions,
        })
        this.preliminaryAssessmentMsg = true
        this.editMode = false
        this.getIsDialogLoading = false
        this.netSurplusDialog = false
      } catch (err) {
        this.getIsDialogLoading = false
        this.netSurplusDialog = false
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      }
    },
    async saveApplication() {
      try {
        const { id, applicationId } = this.$route.params
        let isValid = false
        await this.$refs.loanSubmissionForm.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          const nodes = document.querySelectorAll('.loanSubmission .is-error')
          if (nodes && nodes.length > 0 && nodes[0]) {
            this.$nextTick(() => {
              scrollToElement(nodes[0])
            })
          }
          return
        }
        this.getIsDialogLoading = true
        await this.$store.dispatch('applications/saveLoanSubmission', {
          loanDetails: { ...this.loanData.appData },
          id,
          applicationId,
        })
        this.getIsDialogLoading = false
      } catch (err) {
        this.getIsDialogLoading = false
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      }
    },
    checkTradeInLiability() {
      let remainingLiaArr = []
      remainingLiaArr =
        tradeInLiabilityCommon(
          this.sharedData?.tradeIn?._id,
          this.sharedData?.primaryApplicantData?.liabilities
        ) ?? []

      if (remainingLiaArr?.length)
        this.remainingLiability = remainingLiaArr[0].totalRemaining
    },
    isDocumentFoundInSharedDocs(purpose) {
      const { sharedDocuments } = this.sharedData
      return sharedDocuments?.find(
        (doc) => doc.purpose.toLowerCase() === purpose.toLowerCase()
      )
    },
  },
}
