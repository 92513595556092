//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  RUN_CREDIT_REPORT_TITLE,
  RUN_CREDIT_CHECK_TITLE,
  BODY_MESSAGE_FOR_NO_OF_ATTEMPTS,
  CREDIT_RUN_REPORT_DESCRIPTION,
  REGEX_FOR_APPLICATION_NAME,
  REGEX_FOR_APPLICATION_TYPE,
  ERROR_MESSAGE_FOR_TO_MANY_EQUIFAX_CREDIT_ENQUIRY,
  CREDIT_CHECK_SUCCESS_MESSAGE,
  ERROR_TYPE_CREDIT_FILE_NOT_FOUND,
  ERROR_TYPE_NO_EXISTING_CREDIT_FILE_FOUND,
  ERROR_TYPE_OTHER,
  ERROR_TYPE_FILE_BAN,
  HEADER_MESSAGE_FOR_ERROR_TYPE_FILE_NOT_FOUND,
  HEADER_MESSAGE_FOR_ERROR_TYPE_CREDIT_FILE_NOT_FOUND,
  HEADER_MESSAGE_FOR_ERROR_TYPE_UNEXPECTED_ERROR,
  HEADER_MESSAGE_FOR_ERROR_TYPE_FILE_BAN,
  BODY_MESSAGE_FOR_ERROR_TYPE_FILE_BAN,
  BODY_MESSAGE_FOR_UNEXPECTED_ERROR,
  BODY_MESSAGE_FOR_ERROR_TYPE_CREDIT_FILE_NOT_FOUND,
  BODY_MESSAGE_FOR_ERROR_TYPE_FILE_NOT_FOUND,
  HEADER_MESSAGE_FOR_TOO_MANY_EQUIFAX_CREDIT_ENQUIRY,
  LABEL_COMMERCIAL,
  REGEX_FOR_BUSINESS_NAME,
  COMMERCIAL_CREDIT_RUN_REPORT_DESCRIPTION,
} from '~/constants'
import {
  LABEL_CANCEL,
  LABEL_FOR_CLOSE,
  LABEL_PRIMARY_APPLICANT,
  LABEL_SECONDARY_APPLICANT,
  LABEL_DOCUMENT_PRIMARY,
  LABEL_SHARED,
} from '~/constants/applications'
import { axiosErrorMessageExtractor } from '~/utils/error'

export default {
  props: {
    dialogData: {
      type: Object,
      default: null,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    dialogFor: {
      type: String,
      default: null,
    },
    applicantId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      getIsDialogLoading: false,
      LABEL_CANCEL,
      LABEL_FOR_CLOSE,
      RUN_CREDIT_REPORT_TITLE,
      RUN_CREDIT_CHECK_TITLE,
      BODY_MESSAGE_FOR_NO_OF_ATTEMPTS,
      LABEL_PRIMARY_APPLICANT,
      LABEL_SECONDARY_APPLICANT,
      LABEL_DOCUMENT_PRIMARY,
      LABEL_SHARED,
      CREDIT_RUN_REPORT_DESCRIPTION,
      CREDIT_CHECK_SUCCESS_MESSAGE,
      REGEX_FOR_APPLICATION_NAME,
      REGEX_FOR_APPLICATION_TYPE,
      REGEX_FOR_BUSINESS_NAME,
      COMMERCIAL_CREDIT_RUN_REPORT_DESCRIPTION,
      ERROR_MESSAGE_FOR_TO_MANY_EQUIFAX_CREDIT_ENQUIRY,
      showError: false,
      headerOfError: '',
      bodyOfError: '',
      noOfAttempts: '',
      remainingAttempsForCreditEnquiry: null,
      BODY_MESSAGE_FOR_UNEXPECTED_ERROR,
      HEADER_MESSAGE_FOR_TOO_MANY_EQUIFAX_CREDIT_ENQUIRY,
      LABEL_COMMERCIAL,
    }
  },
  watch: {
    showDialog() {
      this.showError = false
    },
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  computed: {
    isCommercialCredit() {
      return this.dialogFor == LABEL_COMMERCIAL
    },
  },
  methods: {
    closeReportDialog() {
      this.$nuxt.$emit('closeDialog')
    },
    async runCreditReport() {
      try {
        this.getIsDialogLoading = true
        const { id, applicationId } = this.$route.params
        const { _id: applicantId } = this.dialogData

        let result
        if (this.isCommercialCredit) {
          result = await this.$store.dispatch(
            'applications/runCommercialCreditReport',
            {
              id,
              applicationId,
            }
          )
        } else {
          result = await this.$store.dispatch('applications/runCreditReport', {
            id,
            applicationId,
            applicantId,
          })
        }
        if (result?.data?.isError) {
          this.remainingAttempsForCreditEnquiry =
            result?.data?.remainingAttempsForCreditEnquiry
          this.showError = true
          switch (result?.data?.errorType) {
            case ERROR_TYPE_NO_EXISTING_CREDIT_FILE_FOUND:
              this.headerOfError = HEADER_MESSAGE_FOR_ERROR_TYPE_FILE_NOT_FOUND
              this.bodyOfError = BODY_MESSAGE_FOR_ERROR_TYPE_FILE_NOT_FOUND
              break
            case ERROR_TYPE_CREDIT_FILE_NOT_FOUND:
              this.headerOfError =
                HEADER_MESSAGE_FOR_ERROR_TYPE_CREDIT_FILE_NOT_FOUND
              this.bodyOfError =
                BODY_MESSAGE_FOR_ERROR_TYPE_CREDIT_FILE_NOT_FOUND
              break
            case ERROR_TYPE_OTHER:
              this.headerOfError =
                HEADER_MESSAGE_FOR_ERROR_TYPE_UNEXPECTED_ERROR
              this.bodyOfError = BODY_MESSAGE_FOR_UNEXPECTED_ERROR
              break
            case ERROR_TYPE_FILE_BAN:
              this.headerOfError = HEADER_MESSAGE_FOR_ERROR_TYPE_FILE_BAN
              this.bodyOfError = BODY_MESSAGE_FOR_ERROR_TYPE_FILE_BAN
              break
          }
          this.noOfAttempts = BODY_MESSAGE_FOR_NO_OF_ATTEMPTS.replace(
            /\[NO_OF_ATTEMPTS\]/,
            this.remainingAttempsForCreditEnquiry ?? 0
          )
        } else {
          this.closeReportDialog()
        }
      } catch (err) {
        this.showError = true
        if (
          err.response?.data?.error ===
          ERROR_MESSAGE_FOR_TO_MANY_EQUIFAX_CREDIT_ENQUIRY
        ) {
          this.showError = true
          this.headerOfError =
            HEADER_MESSAGE_FOR_TOO_MANY_EQUIFAX_CREDIT_ENQUIRY
          this.bodyOfError = `Your maximum limit of 3 credit checks has been reached. Please contact admin to run more credit checks.`
          this.noOfAttempts = ''
        } else {
          this.closeReportDialog()
          this.$notify({
            title: 'Error',
            message: axiosErrorMessageExtractor(err),
            type: 'error',
            duration: 5000,
          })
        }
      } finally {
        this.getIsDialogLoading = false
      }
    },
  },
}
