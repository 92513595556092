var render = function () {
  var _vm$loanData, _vm$loanData$appData, _vm$loanData$appData$, _vm$loanData$appData$2, _vm$loanData2, _vm$loanData2$appData, _vm$loanData3, _vm$loanData3$appData, _vm$loanData3$appData2, _vm$loanData3$appData3, _vm$loanData4, _vm$loanData4$appData, _vm$loanData4$appData2, _vm$loanData5, _vm$loanData5$appData, _vm$loanData6, _vm$loanData6$appData, _vm$loanData6$appData2, _vm$loanData6$appData3, _vm$loanData7, _vm$loanData7$appData, _vm$loanData7$appData2, _vm$loanData7$appData3, _vm$loanData8, _vm$loanData8$appData, _vm$loanData8$appData2, _vm$loanData8$appData3, _vm$loanData$appData2, _vm$loanData$appData3, _vm$loanData$appData4, _vm$loanData$appData5, _vm$loanData$appData6, _vm$loanData$appData7, _vm$loanData$appData8, _vm$loanData9, _vm$loanData9$appData, _vm$loanData9$appData2, _vm$loanData9$appData3, _vm$loanData10, _vm$loanData10$appDat, _vm$loanData10$appDat2, _vm$loanData10$appDat3, _vm$numberWithCommas, _vm$loanData11, _vm$loanData11$appDat, _vm$loanData11$appDat2, _vm$loanData11$appDat3, _vm$REASON_FOR_BALLOO, _vm$REASON_FOR_BALLOO2, _vm$loanData12, _vm$loanData12$appDat, _vm$loanData13, _vm$loanData13$appDat, _vm$loanData14, _vm$loanData14$appDat, _vm$loanData14$appDat2, _vm$loanData$appData9, _vm$loanData$appData10, _vm$loanData$appData11, _vm$loanData$appData12, _vm$loanData15, _vm$loanData15$appDat, _this$loanData, _this$loanData$appDat, _this$loanData$appDat2, _this$loanData$appDat3, _this$loanData2, _this$loanData2$appDa, _this$loanData2$appDa2, _this$loanData2$appDa3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }]
  }, [!_vm.isConsumer && _vm.showWarningForContactsMismatch ? _c('div', {
    staticClass: "alertMessage el-alert el-alert--primary text-black is-light alertbox",
    attrs: {
      "role": "alert"
    }
  }, [_c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title font-bolder text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_NO_OF_CONTACTS_MISMATCH))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description text-xs",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v(_vm._s(_vm.warningMessage))])])]) : _vm._e(), _vm._v(" "), _c('PrivacyFormHeader', {
    attrs: {
      "handle-documents-url": _vm.handleDocumentsUrl,
      "shared-documents": _vm.getSharedDocuments,
      "is-commercial": _vm.isCommercial,
      "title": _vm.privacyPolicyCheckTitle,
      "description": _vm.privacyPolicyCheckDiscription,
      "background-class": !_vm.isPrivacyFormAreSigned ? 'el-alert--warning' : 'el-alert--success'
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [!_vm.isPrivacyFormAreSigned ? _c('img', {
          staticClass: "mr-4",
          attrs: {
            "src": require("@/assets/icons/alert-triangle.svg"),
            "alt": "okay"
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "endButton",
      fn: function () {
        return [!_vm.isPrivacyFormAreSigned ? _c('el-button', {
          staticClass: "ml-auto",
          attrs: {
            "plain": "",
            "data-selector": "ld-privacy-view-details-btn"
          },
          on: {
            "click": function ($event) {
              return _vm.redirectToCompliance();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_VIEW_DETAILS))]) : _vm._e()];
      },
      proxy: true
    }])
  }), _vm._v(" "), _vm.preliminaryAssessmentMsg && !_vm.editMode && _vm.isConsumer ? _c('div', [_vm.loanData.appData.loanDetails.preliminaryAssessmentAt ? _c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light alertbox",
    staticStyle: {
      "background": "rgba(0, 184, 73, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert",
      "data-selector": "ls-prelim-alert"
    }
  }, [false ? _c('span', {
    staticClass: "rounded-fifty border border-dark order-label text-center text-xs mr-4"
  }, [_vm._v("1")]) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay",
      "data-selector": "ls-prelim-check-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "ls-prelim-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_PRELIMINARY_ASSESSMENT))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "ls-prelim-alert-desc"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_PRELIMINARY_ASSESSMENT_DETAILS) + "\n          " + _vm._s(_vm.format(new Date(_vm.loanData.appData.loanDetails.preliminaryAssessmentAt), 'dd/MM/yyyy hh:mm aaa')))])]), _vm._v(" "), !_vm.isApplicationSettled ? _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "plain": "",
      "disabled": _vm.isSubmissionButtonDisabled,
      "data-selector": "ls-prelim-edit-submission-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.editSubmissionDetails();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_EDIT_SUBMISSION_DETAILS))]) : _vm._e(), _vm._v(" "), _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "plain": "",
      "data-selector": "ls-prelim-view-assessment-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.viewAssetment(_vm.LABEL_PRELIMINARY_ASSESSMENT);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_VIEW_ASSESSMENT))])], 1) : _vm._e()]) : _vm._e(), _vm._v(" "), _vm.preliminaryAssessmentMsg && !_vm.editMode && !_vm.isConsumer ? _c('div', [_vm.loanData.appData.loanDetails.preliminaryAssessmentAt ? _c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light alertbox",
    staticStyle: {
      "background": "rgba(0, 184, 73, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert"
    }
  }, [_c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold"
  }, [_vm._v(_vm._s(_vm.LABEL_SUBMISSION_DETAILS_SAVED))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description"
  }, [_vm._v(_vm._s(_vm.LABEL_SUBMISSION_DETAILS_SAVED_DESCRIPTION) + "\n          " + _vm._s(_vm.format(new Date(_vm.loanData.appData.loanDetails.preliminaryAssessmentAt), 'dd/MM/yyyy hh:mm aaa')))])]), _vm._v(" "), _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "plain": "",
      "disabled": _vm.isSubmissionButtonDisabled
    },
    on: {
      "click": function ($event) {
        return _vm.editSubmissionDetails();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_EDIT_SUBMISSION_DETAILS))])], 1) : _vm._e()]) : _vm._e(), _vm._v(" "), !_vm.editMode ? _c('div', [!_vm.proposalCreated && _vm.isConsumer ? _c('div', {
    staticClass: "pt-6 pb-4"
  }, [_c('b', [_vm._v(_vm._s(_vm.LABEL_NEXT_ACTIONS))])]) : _vm._e(), _vm._v(" "), _vm.proposalCreated && _vm.isConsumer && _vm.loanData.appData.loanDetails.submission.shouldResendCpdOnSubmission ? _c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light mb-4 mt-4 alertbox",
    staticStyle: {
      "background": "rgba(19, 181, 232, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert",
      "data-selector": "cpd-alert"
    }
  }, [false ? _c('span', {
    staticClass: "rounded-fifty border border-dark order-label text-center text-xs mr-4"
  }, [_vm._v("1")]) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/info.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "cpd-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "cpd-alert-subtitle"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE_DETAILS) + "\n        ")])]), _vm._v(" "), _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "type": "primary",
      "disabled": _vm.isSubmissionButtonDisabled,
      "data-selector": "cpd-alert-review-send-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.reviewAndSendProposal(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_REVIEW_AND_SEND_PROPOSAL))])], 1) : _vm._e(), _vm._v(" "), _vm.proposalCreated && _vm.isConsumer && !_vm.loanData.appData.loanDetails.submission.shouldResendCpdOnSubmission ? _c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light mb-4 mt-4 alertbox",
    staticStyle: {
      "background": "rgba(0, 184, 73, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert",
      "data-selector": "cpd-alert"
    }
  }, [false ? _c('span', {
    staticClass: "rounded-fifty border border-dark order-label text-center text-xs mr-4"
  }, [_vm._v("1")]) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay",
      "data-selector": "cpd-check-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "cpd-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "cpd-alert-subtitle"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE_SENT_AT) + "\n          " + _vm._s(_vm.format(new Date(_vm.loanData.appData.loanDetails.creditProposalDisclosureSentAt), 'dd/MM/yyyy hh:mm aaa')) + "\n        ")])]), _vm._v(" "), _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "plain": "",
      "data-selector": "cpd-alert-view-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.viewAssetment(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_VIEW))])], 1) : _vm._e(), _vm._v(" "), (_vm.proposalCreated && !((_vm$loanData = _vm.loanData) !== null && _vm$loanData !== void 0 && (_vm$loanData$appData = _vm$loanData.appData) !== null && _vm$loanData$appData !== void 0 && (_vm$loanData$appData$ = _vm$loanData$appData.loanDetails) !== null && _vm$loanData$appData$ !== void 0 && (_vm$loanData$appData$2 = _vm$loanData$appData$.submission) !== null && _vm$loanData$appData$2 !== void 0 && _vm$loanData$appData$2.shouldResendCpdOnSubmission) && _vm.isConsumer || !_vm.isConsumer) && (((_vm$loanData2 = _vm.loanData) === null || _vm$loanData2 === void 0 ? void 0 : (_vm$loanData2$appData = _vm$loanData2.appData) === null || _vm$loanData2$appData === void 0 ? void 0 : _vm$loanData2$appData.status) === _vm.APPLICATION_STATUS_STARTED || !((_vm$loanData3 = _vm.loanData) !== null && _vm$loanData3 !== void 0 && (_vm$loanData3$appData = _vm$loanData3.appData) !== null && _vm$loanData3$appData !== void 0 && (_vm$loanData3$appData2 = _vm$loanData3$appData.loanDetails) !== null && _vm$loanData3$appData2 !== void 0 && (_vm$loanData3$appData3 = _vm$loanData3$appData2.submission) !== null && _vm$loanData3$appData3 !== void 0 && _vm$loanData3$appData3.submittedAt)) ? _c('div', {
    staticClass: "pt-6 pb-4 text-xs"
  }, [_c('b', [_vm._v(_vm._s(_vm.LABEL_NEXT_ACTIONS))])]) : _vm._e(), _vm._v(" "), (_vm.proposalCreated && !((_vm$loanData4 = _vm.loanData) !== null && _vm$loanData4 !== void 0 && (_vm$loanData4$appData = _vm$loanData4.appData) !== null && _vm$loanData4$appData !== void 0 && (_vm$loanData4$appData2 = _vm$loanData4$appData.loanDetails) !== null && _vm$loanData4$appData2 !== void 0 && _vm$loanData4$appData2.submission.shouldResendCpdOnSubmission) || !_vm.isConsumer) && (((_vm$loanData5 = _vm.loanData) === null || _vm$loanData5 === void 0 ? void 0 : (_vm$loanData5$appData = _vm$loanData5.appData) === null || _vm$loanData5$appData === void 0 ? void 0 : _vm$loanData5$appData.status) === _vm.APPLICATION_STATUS_STARTED || !((_vm$loanData6 = _vm.loanData) !== null && _vm$loanData6 !== void 0 && (_vm$loanData6$appData = _vm$loanData6.appData) !== null && _vm$loanData6$appData !== void 0 && (_vm$loanData6$appData2 = _vm$loanData6$appData.loanDetails) !== null && _vm$loanData6$appData2 !== void 0 && (_vm$loanData6$appData3 = _vm$loanData6$appData2.submission) !== null && _vm$loanData6$appData3 !== void 0 && _vm$loanData6$appData3.submittedAt) || ((_vm$loanData7 = _vm.loanData) === null || _vm$loanData7 === void 0 ? void 0 : (_vm$loanData7$appData = _vm$loanData7.appData) === null || _vm$loanData7$appData === void 0 ? void 0 : (_vm$loanData7$appData2 = _vm$loanData7$appData.loanDetails) === null || _vm$loanData7$appData2 === void 0 ? void 0 : (_vm$loanData7$appData3 = _vm$loanData7$appData2.submission) === null || _vm$loanData7$appData3 === void 0 ? void 0 : _vm$loanData7$appData3.submittedAt) === null) ? _c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light mb-4 alertbox",
    staticStyle: {
      "background": "rgba(19, 181, 232, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert",
      "data-selector": "ls-app-submission-alert"
    }
  }, [false ? _c('span', {
    staticClass: "rounded-fifty border border-dark order-label text-center text-xs mr-4"
  }, [_vm._v("1")]) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/info.svg"),
      "alt": "okay",
      "alertbox": ""
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "ls-app-submission-title"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_APPLICATION_SUBMISSION))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "ls-app-submission-subtitle"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_APPLICATION_SUBMISSION_DETAILS) + "\n        ")])]), _vm._v(" "), _c('el-button', {
    staticClass: "ml-auto",
    class: {
      'is-disabled': _vm.isSubmissionButtonDisabled
    },
    attrs: {
      "type": "primary",
      "plain": !_vm.proposalCreated && _vm.isConsumer,
      "disabled": _vm.isValidStatus,
      "data-selector": "ls-submit-application-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submitApplication();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_SUBMIT_APPLICATION))])], 1) : _vm._e(), _vm._v(" "), (_vm.proposalCreated || !_vm.isConsumer) && (_vm$loanData8 = _vm.loanData) !== null && _vm$loanData8 !== void 0 && (_vm$loanData8$appData = _vm$loanData8.appData) !== null && _vm$loanData8$appData !== void 0 && (_vm$loanData8$appData2 = _vm$loanData8$appData.loanDetails) !== null && _vm$loanData8$appData2 !== void 0 && (_vm$loanData8$appData3 = _vm$loanData8$appData2.submission) !== null && _vm$loanData8$appData3 !== void 0 && _vm$loanData8$appData3.submittedAt ? _c('div', {
    class: !_vm.isConsumer ? 'mt-4 el-alert el-alert--primary text-black is-light mb-4 alertbox' : 'el-alert el-alert--primary text-black is-light mb-4 alertbox',
    staticStyle: {
      "background": "rgba(0, 184, 73, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert",
      "data-selector": "ls-app-submitted-alert"
    }
  }, [false ? _c('span', {
    staticClass: "rounded-fifty border border-dark order-label text-center text-xs mr-4"
  }, [_vm._v("1")]) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay",
      "data-selector": "ls-app-submitted-check-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "ls-app-submitted-title"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_APPLICATION_SUBMISSION))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "ls-app-submitted-desc"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_APPLICATION_SUBMITTED_AT) + "\n          " + _vm._s(_vm.format(new Date(_vm.loanData.appData.loanDetails.submission.submittedAt), 'dd/MM/yyyy hh:mm aaa')) + "\n        ")])])]) : _vm._e()]) : _vm._e(), _vm._v(" "), _vm.editMode && _vm.isNetSurplusNegativeOrZero ? _c('div', {
    staticClass: "alertMessage el-alert el-alert--primary text-black is-light alertbox",
    attrs: {
      "role": "alert",
      "data-selector": "net-surplus-alert"
    }
  }, [_c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title font-bolder",
    attrs: {
      "data-selector": "ns-alert-title"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.LABEL_APPLICATION_CANNOT_BE_SUBMITTED))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "ns-alert-desc"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_APPLICATION_CANNOT_BE_SUBMITTED_DESCRIPTION))])])]) : _vm._e(), _vm._v(" "), _c('el-card', {
    class: !_vm.editMode ? 'application-form card-body-pt-0 card-header-pb-0' : 'application-form'
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "lender-header-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_LENDER))])]), _vm._v(" "), _vm.loanSubmissionForm && (_vm$loanData$appData2 = _vm.loanData.appData) !== null && _vm$loanData$appData2 !== void 0 && _vm$loanData$appData2.loanDetails ? [_c('el-form', {
    ref: "loanSubmissionForm",
    staticClass: "loanSubmission",
    attrs: {
      "rules": _vm.rules,
      "model": _vm.loanData
    }
  }, [_c('el-row', {
    staticClass: "items-end",
    attrs: {
      "gutter": 40,
      "type": "flex"
    }
  }, [_vm.editMode ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Name",
      "prop": "appData.loanDetails.lender",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_LENDER_NAME,
        trigger: ['blur', 'change']
      }],
      "data-selector": "lender-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.loanData.appData.loanDetails.lender,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails, "lender", $$v);
      },
      expression: "loanData.appData.loanDetails.lender"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "lender-options-container"
    }
  }, _vm._l(_vm.loanData.appAccountData, function (data) {
    return _c('el-option', {
      key: data.index,
      attrs: {
        "label": data.businessName,
        "value": data._id
      }
    });
  }), 1)])], 1)], 1) : _vm._e(), _vm._v(" "), !_vm.editMode ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('p', {
    staticClass: "text-sm py-4",
    attrs: {
      "data-selector": "ls-selected-lender"
    }
  }, [_vm._v(_vm._s((_vm$loanData$appData3 = _vm.loanData.appData) !== null && _vm$loanData$appData3 !== void 0 && (_vm$loanData$appData4 = _vm$loanData$appData3.loanDetails) !== null && _vm$loanData$appData4 !== void 0 && (_vm$loanData$appData5 = _vm$loanData$appData4.lenderData) !== null && _vm$loanData$appData5 !== void 0 && _vm$loanData$appData5.businessName ? (_vm$loanData$appData6 = _vm.loanData.appData) === null || _vm$loanData$appData6 === void 0 ? void 0 : (_vm$loanData$appData7 = _vm$loanData$appData6.loanDetails) === null || _vm$loanData$appData7 === void 0 ? void 0 : (_vm$loanData$appData8 = _vm$loanData$appData7.lenderData) === null || _vm$loanData$appData8 === void 0 ? void 0 : _vm$loanData$appData8.businessName : _vm.LABEL_NOT_AVAILABLE))])]) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "loan-details-header-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_DETAILS))])]), _vm._v(" "), !_vm.editMode ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-purchase-price"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.loanData.appData.loanDetails.submission.purchasePrice >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.submission.purchasePrice) : _vm.LABEL_NOT_AVAILABLE) + "\n              ")])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('label', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "for": "cheese"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.LABEL_TRADE_IN_EQUITY) + "\n              ")])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-trade-in-price"
    }
  }, [_vm._v(_vm._s(((_vm$loanData9 = _vm.loanData) === null || _vm$loanData9 === void 0 ? void 0 : (_vm$loanData9$appData = _vm$loanData9.appData) === null || _vm$loanData9$appData === void 0 ? void 0 : (_vm$loanData9$appData2 = _vm$loanData9$appData.loanDetails) === null || _vm$loanData9$appData2 === void 0 ? void 0 : (_vm$loanData9$appData3 = _vm$loanData9$appData2.submission) === null || _vm$loanData9$appData3 === void 0 ? void 0 : _vm$loanData9$appData3.tradeInAmount) >= 0 ? _vm.numberWithCommas((_vm$loanData10 = _vm.loanData) === null || _vm$loanData10 === void 0 ? void 0 : (_vm$loanData10$appDat = _vm$loanData10.appData) === null || _vm$loanData10$appDat === void 0 ? void 0 : (_vm$loanData10$appDat2 = _vm$loanData10$appDat.loanDetails) === null || _vm$loanData10$appDat2 === void 0 ? void 0 : (_vm$loanData10$appDat3 = _vm$loanData10$appDat2.submission) === null || _vm$loanData10$appDat3 === void 0 ? void 0 : _vm$loanData10$appDat3.tradeInAmount) : _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e(), _vm._v(" "), !_vm.editMode && _vm.loanData.appData.vehicle ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_vm.remainingLiability ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v("\n                " + _vm._s(_vm.LABEL_TRADING_LIABILITIES) + "\n              ")])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1"
  }, [_vm._v("\n                " + _vm._s(_vm.numberWithCommas(_vm.remainingLiability)) + "\n              ")])])]) : _vm._e(), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_DEPOSIT))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-deposit"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.loanData.appData.loanDetails.submission.depositAmount >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.submission.depositAmount) : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_NAF))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-naf"
    }
  }, [_vm._v("\n                " + _vm._s((_vm$numberWithCommas = _vm.numberWithCommas(_vm.loanData.appData.loanDetails.submission.netAmountFinanced)) !== null && _vm$numberWithCommas !== void 0 ? _vm$numberWithCommas : _vm.LABEL_NOT_AVAILABLE) + "\n              ")])])]), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_BALLOON))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-balloon-percent"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.submission.balloonPct >= 0 ? _vm.currencyDecimalDigitFormat(_vm.loanData.appData.loanDetails.submission.balloonPct) + '%' : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_BALLOON_DOLLAR))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-balloon-amount"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.submission.balloonAmount >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.submission.balloonAmount) : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), ((_vm$loanData11 = _vm.loanData) === null || _vm$loanData11 === void 0 ? void 0 : (_vm$loanData11$appDat = _vm$loanData11.appData) === null || _vm$loanData11$appDat === void 0 ? void 0 : (_vm$loanData11$appDat2 = _vm$loanData11$appDat.loanDetails) === null || _vm$loanData11$appDat2 === void 0 ? void 0 : (_vm$loanData11$appDat3 = _vm$loanData11$appDat2.submission) === null || _vm$loanData11$appDat3 === void 0 ? void 0 : _vm$loanData11$appDat3.balloonPct) > 0 && _vm.isConsumer ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_REASON_FOR_BALLOON_PAYMENT))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-reason-for-balloon"
    }
  }, [_vm._v(_vm._s((_vm$REASON_FOR_BALLOO = (_vm$REASON_FOR_BALLOO2 = _vm.REASON_FOR_BALLOON_PAYMENT_OPTIONS.find(function (reason) {
    return reason.value === _vm.loanData.appData.customerReasonForBalloon;
  })) === null || _vm$REASON_FOR_BALLOO2 === void 0 ? void 0 : _vm$REASON_FOR_BALLOO2.label) !== null && _vm$REASON_FOR_BALLOO !== void 0 ? _vm$REASON_FOR_BALLOO : _vm.LABEL_NOT_AVAILABLE))])])]) : _vm._e(), _vm._v(" "), _vm.loanData.appData.customerReasonForBalloon === _vm.LABEL_BALLOON_REPAYMENT_OTHER ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_OTHER_REASON_FOR_BALLOON_PAYMENT_VIEW))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-other-reason-balloon"
    }
  }, [_vm._v(_vm._s((_vm$loanData12 = _vm.loanData) !== null && _vm$loanData12 !== void 0 && (_vm$loanData12$appDat = _vm$loanData12.appData) !== null && _vm$loanData12$appDat !== void 0 && _vm$loanData12$appDat.customerReasonForBalloonExplanation ? (_vm$loanData13 = _vm.loanData) === null || _vm$loanData13 === void 0 ? void 0 : (_vm$loanData13$appDat = _vm$loanData13.appData) === null || _vm$loanData13$appDat === void 0 ? void 0 : _vm$loanData13$appDat.customerReasonForBalloonExplanation : _vm.LABEL_NOT_AVAILABLE))])])]) : _vm._e(), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold mt-2"
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_TREM))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-loan-term"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.submission.loanTermYears ? _vm.loanData.appData.loanDetails.submission.loanTermYears + " " + _vm.LABEL_YEARS : _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e(), _vm._v(" "), _vm.editMode ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
      "prop": "appData.loanDetails.submission.purchasePrice",
      "data-selector": "purchase-price-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "purchase-price-input"
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBallonAmount();
      }
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.purchasePrice,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "purchasePrice", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails.submission.purchasePrice\n                "
    }
  })], 1)], 1), _vm._v(" "), ((_vm$loanData14 = _vm.loanData) === null || _vm$loanData14 === void 0 ? void 0 : (_vm$loanData14$appDat = _vm$loanData14.appData) === null || _vm$loanData14$appDat === void 0 ? void 0 : (_vm$loanData14$appDat2 = _vm$loanData14$appDat.tradeIn) === null || _vm$loanData14$appDat2 === void 0 ? void 0 : _vm$loanData14$appDat2.type) === _vm.APPLICATION_TRADE_IN_VEHICLE ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    staticClass: "tradeInLabel",
    attrs: {
      "prop": "appData.loanDetails.submission.tradeInAmount",
      "label": ""
    }
  }, [_c('label', {
    attrs: {
      "for": "cheese"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "#e14c4c"
    }
  }, [_vm._v("* ")]), _vm._v(_vm._s(_vm.LABEL_TRADE_IN_EQUITY) + "\n              ")]), _vm._v(" "), _c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    model: {
      value: _vm.loanData.appData.loanDetails.submission.tradeInAmount,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "tradeInAmount", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails.submission.tradeInAmount\n                "
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.loanData.appData.vehicle ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_DEPOSIT,
      "prop": "appData.loanDetails.submission.depositAmount",
      "data-selector": "deposit-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "deposit-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('appData.loanDetails.submission.depositAmount');
      }
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.depositAmount,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "depositAmount", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails.submission.depositAmount\n                "
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_NAF,
      "prop": "appData.loanDetails.submission.netAmountFinanced",
      "data-selector": "naf-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "naf-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('appData.loanDetails.submission.netAmountFinanced');
      }
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.netAmountFinanced,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "netAmountFinanced", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails.submission.netAmountFinanced\n                "
    }
  })], 1)], 1), _vm._v(" "), _vm.remainingLiability > 0 ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    staticClass: "tradeInLabel"
  }, [_c('label', [_vm._v(_vm._s(_vm.LABEL_TRADING_LIABILITIES) + " ")]), _vm._v(" "), _c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.remainingLiability,
      callback: function ($$v) {
        _vm.remainingLiability = $$v;
      },
      expression: "remainingLiability"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_BALLOON,
      "prop": "appData.loanDetails.submission.balloonPct",
      "data-selector": "balloon-percent-input-container"
    }
  }, [_c('formatted-numeric-input', {
    attrs: {
      "data-selector": "balloon-percent-input"
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBallonAmount();
      }
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.balloonPct,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "balloonPct", $$v);
      },
      expression: "loanData.appData.loanDetails.submission.balloonPct"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LOAN_BALLOON_DOLLAR,
      "prop": "appData.loanDetails.submission.balloonAmount",
      "data-selector": "balloon-amt-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "balloon-amt-input"
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBallonPct();
      }
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.balloonAmount,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "balloonAmount", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails.submission.balloonAmount\n                "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LOAN_TREM,
      "prop": "appData.loanDetails.submission.loanTermYears",
      "data-selector": "loan-term-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.loanTermYears,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "loanTermYears", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails.submission.loanTermYears\n                "
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "loan-term-options-container"
    }
  }, _vm._l(_vm.LOAN_TERM_YEAR_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.value,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _vm.loanData.appData.loanDetails.submission.balloonPct > 0 && _vm.isConsumer ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_REASON_FOR_BALLOON_PAYMENT,
      "prop": "appData.customerReasonForBalloon",
      "data-selector": "reason-for-balloon-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.loanData.appData.customerReasonForBalloon,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData, "customerReasonForBalloon", $$v);
      },
      expression: "loanData.appData.customerReasonForBalloon"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "reason-for-balloon-options-container"
    }
  }, _vm._l(_vm.REASON_FOR_BALLOON_PAYMENT_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.value,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.editMode && _vm.loanData.appData.customerReasonForBalloon === _vm.LABEL_BALLOON_REPAYMENT_OTHER ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "placeholder": _vm.LABEL_BALLOON_REPAYMENT_OTHER,
      "autocomplete": "off",
      "data-selector": "other-reason-balloon-input"
    },
    model: {
      value: _vm.loanData.appData.customerReasonForBalloonExplanation,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData, "customerReasonForBalloonExplanation", $$v);
      },
      expression: "loanData.appData.customerReasonForBalloonExplanation"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_RATE_AND_FEE))])]), _vm._v(" "), !_vm.editMode ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_BASE_RATE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-lender-base-rate"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.loanData.appData.loanDetails.submission.baseRate >= 0 ? _vm.loanData.appData.loanDetails.submission.baseRate : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_MONTHLY_REPAYMENT_AMOUNT))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-monthly-repayment"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.submission.monthlyRepayment >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.submission.monthlyRepayment) : _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e(), _vm._v(" "), _vm.editMode ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_BASE_RATE,
      "prop": "appData.loanDetails.submission.baseRate",
      "data-selector": "lender-base-rate-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "max": 100,
      "data-selector": "lender-base-rate-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('appData.loanDetails.submission.baseRate');
      }
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.baseRate,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "baseRate", $$v);
      },
      expression: "loanData.appData.loanDetails.submission.baseRate"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_MONTHLY_REPAYMENT_AMOUNT,
      "prop": "appData.loanDetails.submission.monthlyRepayment",
      "data-selector": "monthly-repayment-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "monthly-repayment-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('appData.loanDetails.submission.monthlyRepayment');
      }
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.monthlyRepayment,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "monthlyRepayment", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails.submission.monthlyRepayment\n                "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_CUSTOMER_RATE,
      "prop": "appData.loanDetails.submission.customerRate",
      "data-selector": "customer-rate-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "max": 100,
      "data-selector": "customer-rate-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('appData.loanDetails.submission.customerRate');
      }
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.customerRate,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "customerRate", $$v);
      },
      expression: "loanData.appData.loanDetails.submission.customerRate"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LENDER_MONTHLY_FEE,
      "prop": "appData.loanDetails.submission.accountKeepingFee",
      "data-selector": "account-keeping-input-selector"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "account-keeping-input"
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.accountKeepingFee,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "accountKeepingFee", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails.submission.accountKeepingFee\n                "
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), !_vm.editMode ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_CUSTOMER_RATE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-customer-rate"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.loanData.appData.loanDetails.submission.customerRate >= 0 ? _vm.loanData.appData.loanDetails.submission.customerRate : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LENDER_MONTHLY_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-account-keeping-fee"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.submission.accountKeepingFee >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.submission.accountKeepingFee) : _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e(), _vm._v(" "), !_vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LENDER_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-lender-est-fee"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.submission.lenderEstablishmentFee >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.submission.lenderEstablishmentFee) : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _vm.loanData.appData.loanDetails ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_PSPR_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-ppsr-fee"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.submission.ppsrFee >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.submission.ppsrFee) : _vm.LABEL_NOT_AVAILABLE))])])]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LENDER_FEE,
      "prop": "appData.loanDetails.submission.lenderEstablishmentFee",
      "data-selector": "lender-est-fee-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "lender-est-fee-input"
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.lenderEstablishmentFee,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "lenderEstablishmentFee", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails.submission\n                    .lenderEstablishmentFee\n                "
    }
  })], 1)], 1), _vm._v(" "), _vm.loanData.appData.loanDetails ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_PSPR_FEE,
      "prop": "appData.loanDetails.submission.ppsrFee",
      "data-selector": "pspr-fee-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "pspr-fee-input"
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.ppsrFee,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "ppsrFee", $$v);
      },
      expression: "loanData.appData.loanDetails.submission.ppsrFee"
    }
  })], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), !_vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_ORIGINATION_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-broker-fee"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.submission.brokerFee >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.submission.brokerFee) : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _vm.loanData.appData.loanDetails ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_PARTNER_REFERRAL_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-partner-fin-ref-fee"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.submission.partnerFinanceReferralFee >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.submission.partnerFinanceReferralFee) : _vm.LABEL_NOT_AVAILABLE))])])]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_ORIGINATION_FEE,
      "prop": "appData.loanDetails.submission.brokerFee",
      "data-selector": "broker-fee-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "broker-fee-input"
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.brokerFee,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "brokerFee", $$v);
      },
      expression: "loanData.appData.loanDetails.submission.brokerFee"
    }
  })], 1)], 1), _vm._v(" "), _vm.loanData.appData.loanDetails ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_PARTNER_REFERRAL_FEE,
      "prop": "appData.loanDetails.submission.partnerFinanceReferralFee",
      "data-selector": "partner-fin-ref-fee-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "disabled": true,
      "data-selector": "partner-fin-ref-fee-input"
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.partnerFinanceReferralFee,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "partnerFinanceReferralFee", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails.submission\n                    .partnerFinanceReferralFee\n                "
    }
  })], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), !_vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    staticClass: "mt-4 mb-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LENDING_COMMISSION))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ls-saved-lending-commission"
    }
  }, [_vm._v(_vm._s(((_vm$loanData$appData9 = _vm.loanData.appData) === null || _vm$loanData$appData9 === void 0 ? void 0 : (_vm$loanData$appData10 = _vm$loanData$appData9.loanDetails) === null || _vm$loanData$appData10 === void 0 ? void 0 : (_vm$loanData$appData11 = _vm$loanData$appData10.submission) === null || _vm$loanData$appData11 === void 0 ? void 0 : _vm$loanData$appData11.lendingCommision) >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.submission.lendingCommision) : _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e(), _vm._v(" "), _vm.editMode && (_vm$loanData$appData12 = _vm.loanData.appData) !== null && _vm$loanData$appData12 !== void 0 && _vm$loanData$appData12.loanDetails ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LENDING_COMMISSION,
      "prop": "appData.loanDetails.submission.lendingCommision",
      "data-selector": "lending-commission-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "lending-commission-input"
    },
    model: {
      value: _vm.loanData.appData.loanDetails.submission.lendingCommision,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails.submission, "lendingCommision", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails.submission.lendingCommision\n                "
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isConsumer ? _c('div', {
    staticClass: "clearfix card-header mt-4"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "questions-header-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_QUESTIONS))])]) : _vm._e(), _vm._v(" "), !_vm.editMode && _vm.isConsumer ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_IS_THE_CUSTOMER_AGREEABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-sm mt-1 font-extrabold",
    attrs: {
      "data-selector": "ls-saved-agreeable-to-loan"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.doesApplicantAgreeWithProposedLoanDetails ? 'Yes' : 'No'))])])])], 1) : _vm._e(), _vm._v(" "), _vm.isConsumer && _vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    staticClass: "loan-details-questions",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "md": 15
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_IS_THE_CUSTOMER_AGREEABLE,
      "prop": "appData.loanDetails.doesApplicantAgreeWithProposedLoanDetails",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_DOES_APPLICANT_AGRRE_WITH_PROPOSED_LOAN_DETAILS,
        trigger: ['blur', 'change']
      }],
      "data-selector": "q-agreeable-to-loan-input-container"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.loanData.appData.loanDetails.doesApplicantAgreeWithProposedLoanDetails,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails, "doesApplicantAgreeWithProposedLoanDetails", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails\n                    .doesApplicantAgreeWithProposedLoanDetails\n                "
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true,
      "data-selector": "agreeable-yes"
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": false,
      "data-selector": "agreeable-no"
    }
  }, [_vm._v("No")])], 1)], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isConsumer && !_vm.editMode && _vm.loanData.appData ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_DOES_THE_PROPOSED_LOAN_SATISFY))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-sm mt-1 font-extrabold",
    attrs: {
      "data-selector": "ls-saved-loan-satisfy"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.doesSatisfyConsumersObjectivesAndRequirements ? 'Yes' : 'No'))])])])], 1) : _vm._e(), _vm._v(" "), _vm.isConsumer && _vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    staticClass: "loan-details-questions",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "md": 15
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOES_THE_PROPOSED_LOAN_SATISFY,
      "prop": "appData.loanDetails.doesSatisfyConsumersObjectivesAndRequirements",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_DOES_SATISFY_CONSUMERS_OBJECTIVES_AND_REQUIREMENT,
        trigger: ['blur', 'change']
      }],
      "data-selector": "q-loan-satisfy-input-container"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.loanData.appData.loanDetails.doesSatisfyConsumersObjectivesAndRequirements,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails, "doesSatisfyConsumersObjectivesAndRequirements", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails\n                    .doesSatisfyConsumersObjectivesAndRequirements\n                "
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true,
      "data-selector": "loan-satisfy-yes"
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": false,
      "data-selector": "loan-satisfy-no"
    }
  }, [_vm._v("No")])], 1)], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isConsumer && !_vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 14
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_WILL_THE_COSTOMER_BE_ABLE_TO_COMPLY))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 14
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-sm mt-1 font-extrabold",
    attrs: {
      "data-selector": "ls-saved-comply-w-obligations"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.doesApplicantComplyWithFinancialObligations ? 'Yes' : 'No'))])])])], 1) : _vm._e(), _vm._v(" "), _vm.isConsumer && _vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    staticClass: "loan-details-questions",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "md": 15
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_WILL_THE_COSTOMER_BE_ABLE_TO_COMPLY,
      "prop": "appData.loanDetails.doesApplicantComplyWithFinancialObligations",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_DOES_APPLICANT_COMPLY_WITH_FINANCIAL_OBLIGATIONS,
        trigger: ['blur', 'change']
      }],
      "data-selector": "q-comply-w-obligations-input-container"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.loanData.appData.loanDetails.doesApplicantComplyWithFinancialObligations,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails, "doesApplicantComplyWithFinancialObligations", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails\n                    .doesApplicantComplyWithFinancialObligations\n                "
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true,
      "data-selector": "comply-w-o-yes"
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": false,
      "data-selector": "comply-w-o-no"
    }
  }, [_vm._v("No")])], 1)], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isConsumer && !_vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 14
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_DO_YOU_AGREE_THRERE_ARE_NO_KNOWN_OR_LIKELY))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 14
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-sm mt-1 font-extrabold",
    attrs: {
      "data-selector": "ls-saved-circum-change"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.loanData.appData.loanDetails.doesFutureChangesImpactPropsedLoan ? 'Yes' : 'No'))])])])], 1) : _vm._e(), _vm._v(" "), _vm.isConsumer && _vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    staticClass: "loan-details-questions",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "md": 15
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DO_YOU_AGREE_THRERE_ARE_NO_KNOWN_OR_LIKELY,
      "prop": "appData.loanDetails.doesFutureChangesImpactPropsedLoan",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_DOES_FUTURE_CHANGES_IMPACT_PROPOSED_LOAN,
        trigger: ['blur', 'change']
      }],
      "data-selector": "q-circum-change-input-container"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.loanData.appData.loanDetails.doesFutureChangesImpactPropsedLoan,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails, "doesFutureChangesImpactPropsedLoan", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails\n                    .doesFutureChangesImpactPropsedLoan\n                "
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true,
      "data-selector": "circum-change-yes"
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": false,
      "data-selector": "circum-change-no"
    }
  }, [_vm._v("No")])], 1)], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isConsumer && !_vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 14
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_HAVE_YOU_MADE_THE_CUSTOMER_AWARE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 14
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-sm mt-1 font-extrabold",
    attrs: {
      "data-selector": "ls-saved-aware-early"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.loanData.appData.loanDetails.isNoKnownOrLikelyFutureChangeInCircumstancesAgreed ? _vm.LABEL_YES : _vm.LABEL_NO))])])])], 1) : _vm._e(), _vm._v(" "), _vm.isConsumer && _vm.editMode && (_vm$loanData15 = _vm.loanData) !== null && _vm$loanData15 !== void 0 && (_vm$loanData15$appDat = _vm$loanData15.appData) !== null && _vm$loanData15$appDat !== void 0 && _vm$loanData15$appDat.loanDetails ? _c('el-row', {
    staticClass: "loan-details-questions",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "md": 15
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_HAVE_YOU_MADE_THE_CUSTOMER_AWARE,
      "prop": "appData.loanDetails.isNoKnownOrLikelyFutureChangeInCircumstancesAgreed",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_DOES_FUTURE_CHANGES_IMPACT_PROPOSED_LOAN,
        trigger: ['blur', 'change']
      }],
      "data-selector": "q-aware-early-input-container"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.loanData.appData.loanDetails.isNoKnownOrLikelyFutureChangeInCircumstancesAgreed,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails, "isNoKnownOrLikelyFutureChangeInCircumstancesAgreed", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails\n                    .isNoKnownOrLikelyFutureChangeInCircumstancesAgreed\n                "
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true,
      "data-selector": "aware-early-yes"
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": false,
      "data-selector": "aware-early-no"
    }
  }, [_vm._v("No")])], 1)], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isConsumer && !_vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 14
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_DESCRIBE_HOW_THE_SELECTED_LENDER_AND_PRODUCT_MEET))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 14
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    staticStyle: {
      "font-weight": "500"
    },
    attrs: {
      "data-selector": "ls-saved-sel-lender-obj-note"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.howDoesSelectedLenderMeetCustomersNeed ? _vm.loanData.appData.loanDetails.howDoesSelectedLenderMeetCustomersNeed : _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e(), _vm._v(" "), _vm.isConsumer && _vm.editMode && _vm.loanData.appData.loanDetails ? _c('el-row', {
    staticClass: "col-fifty loan-details-questions",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DESCRIBE_HOW_THE_SELECTED_LENDER_AND_PRODUCT_MEET,
      "prop": "appData.loanDetails.howDoesSelectedLenderMeetCustomersNeed",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_HOW_DOES_SELECTED_LENDER_MEET_CUSTOMERS_NEED,
        trigger: ['blur', 'change']
      }],
      "data-selector": "selected-lender-obj-note-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "placeholder": _vm.PLACEHOLDER_FOR_ASSET_DESCRIPTION,
      "autocomplete": "off",
      "data-selector": "selected-lender-obj-note-input"
    },
    model: {
      value: _vm.loanData.appData.loanDetails.howDoesSelectedLenderMeetCustomersNeed,
      callback: function ($$v) {
        _vm.$set(_vm.loanData.appData.loanDetails, "howDoesSelectedLenderMeetCustomersNeed", $$v);
      },
      expression: "\n                  loanData.appData.loanDetails\n                    .howDoesSelectedLenderMeetCustomersNeed\n                "
    }
  })], 1)], 1)], 1) : _vm._e()], 1)] : _vm._e()], 2), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "download-app-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.checkNetSurPlus(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_DOWNLOAD_APPLICATION))]), _vm._v(" "), _vm.editMode ? _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "disabled": !_vm.isPrivacyFormAreSigned || _vm.isNetSurplusNegativeOrZero || _vm.isSubmissionButtonDisabled,
      "loading": _vm.btnLoading,
      "data-selector": "ls-save-details-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.checkNetSurPlus(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.isConsumer ? _vm.LABEL_SAVE_DETAILS_AND_GENERATE_ASSETMENT : _vm.LABEL_SAVE_DETAILS))]) : _vm._e()], 1), _vm._v(" "), _c('LoanSubmissionDialog', {
    attrs: {
      "type": _vm.dialogOpenType,
      "dialog-opened": _vm.dialogOpened,
      "title": _vm.dialogTitle,
      "subtitle": _vm.dialogSubTitle,
      "loan-details": _vm.loanData.appData
    }
  }), _vm._v(" "), _c('EditSubmissionConfirmDialog', {
    attrs: {
      "type": _vm.dialogOpenType,
      "dialog-opened": _vm.editSubmissionDialogOpened,
      "title": _vm.dialogTitle,
      "subtitle": _vm.dialogSubTitle
    }
  }), _vm._v(" "), _c('AlertDialog', {
    attrs: {
      "type": 'netSurPlus',
      "dialog-opened": _vm.netSurplusDialog,
      "subtitle": _vm.dailogDescription,
      "net-value": _vm.totalNetSurplus,
      "loading-dialog": _vm.getIsDialogLoading
    }
  }), _vm._v(" "), _c('CpdSendConfirmDialog', {
    attrs: {
      "dialog-opened": _vm.openSendProposalConfirmation,
      "cpd-url": _vm.cpdUrl,
      "is-settlement": false,
      "is-pre-settlement": false,
      "submit-button-label": _vm.LABEL_SEND
    }
  }), _vm._v(" "), _c('MissingFieldsModal', {
    attrs: {
      "dialog-opened": _vm.openMissingFieldsModal,
      "title": _vm.LABEL_TITLE_FOR_MISSING_FIELDS_MODEL,
      "subtitle": _vm.LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_FIRST + '<b> ' + _vm.APPLICATION_STATUS_SUBMITTED_TO_LENDER + '</b>' + _vm.LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_SECOND,
      "status": _vm.APPLICATION_STATUS_SUBMITTED_TO_LENDER,
      "errors-data": _vm.errorsdata,
      "is-commercial": !_vm.isConsumer,
      "application-data": _vm.sharedData
    },
    on: {
      "closeMissingFieldsModalSubmission": _vm.closeMissingFieldsModalSubmission
    }
  }), _vm._v(" "), _c('LoanSubmissionError', {
    attrs: {
      "has-loan-submission-err": _vm.hasLoanSubmissionErr,
      "loan-submission-errors": _vm.loanSubmissionErrors
    }
  }), _vm._v(" "), _c('ZeroConfirmationDialog', {
    attrs: {
      "dialog-opened": _vm.openZeroConfirmationDialog,
      "title": _vm.LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_TITLE,
      "subtitle": _vm.LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_DISCRIPTION,
      "zero-fields-data": _vm.allZeroValueFieldsData,
      "zero-value-reason": (_this$loanData = this.loanData) === null || _this$loanData === void 0 ? void 0 : (_this$loanData$appDat = _this$loanData.appData) === null || _this$loanData$appDat === void 0 ? void 0 : (_this$loanData$appDat2 = _this$loanData$appDat.loanDetails) === null || _this$loanData$appDat2 === void 0 ? void 0 : (_this$loanData$appDat3 = _this$loanData$appDat2.submission) === null || _this$loanData$appDat3 === void 0 ? void 0 : _this$loanData$appDat3.zeroValueReason,
      "zero-value-reason-type": (_this$loanData2 = this.loanData) === null || _this$loanData2 === void 0 ? void 0 : (_this$loanData2$appDa = _this$loanData2.appData) === null || _this$loanData2$appDa === void 0 ? void 0 : (_this$loanData2$appDa2 = _this$loanData2$appDa.loanDetails) === null || _this$loanData2$appDa2 === void 0 ? void 0 : (_this$loanData2$appDa3 = _this$loanData2$appDa2.submission) === null || _this$loanData2$appDa3 === void 0 ? void 0 : _this$loanData2$appDa3.zeroValueReasonType
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }