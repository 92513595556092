var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "el-alert text-black is-light mb-6",
    class: _vm.backgroundClass,
    attrs: {
      "role": "alert"
    }
  }, [_vm.$slots.icon ? [_vm._t("icon")] : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay",
      "data-selector": "privacy-check-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold"
  }, [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description"
  }, [_vm._v(_vm._s(_vm.description))])]), _vm._v(" "), _vm.$slots.endButton ? [_vm._t("endButton")] : _c('el-dropdown', {
    staticClass: "ml-auto",
    on: {
      "command": _vm.handleDocumentsUrl
    }
  }, [_c('el-button', {
    attrs: {
      "disabled": !_vm.sharedDocuments.length,
      "plain": "",
      "data-selector": "privacy-view-btn"
    }
  }, [_vm._v("\n      View"), _c('i', {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _vm._v(" "), _c('el-dropdown-menu', {
    staticStyle: {
      "width": "6rem"
    },
    attrs: {
      "slot": "dropdown",
      "disabled": !_vm.sharedDocuments.length,
      "data-selector": "privacy-view-options-container"
    },
    slot: "dropdown"
  }, _vm._l(_vm.getSharedDocuments, function (item) {
    return _c('el-dropdown-item', {
      key: item.command,
      attrs: {
        "disabled": item.disabled,
        "command": item.command
      }
    }, [_c('span', {
      staticClass: "pl-1"
    }, [_vm._v(_vm._s(item.label))])]);
  }), 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }