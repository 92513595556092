//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  COMPLIANCE_DOCS_DROPDOWN_MAPPER,
  LABEL_FOR_CREDIT_GUIDE,
  LABEL_FOR_CREDIT_QUOTE,
  LABEL_FOR_DOCUSIGN_CERT,
  LABEL_FOR_PRIVACY_FORM,
} from '~/constants'

export default {
  name: 'PrivacyFormHeader',
  props: {
    sharedDocuments: {
      type: Array,
      required: true,
    },
    isCommercial: {
      type: Boolean,
      required: true,
    },
    handleDocumentsUrl: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    backgroundClass: {
      type: String,
      required: true,
    },
  },
  computed: {
    getSharedDocuments() {
      if (this.sharedDocuments.length > 0) {
        return this.sharedDocuments
      }
      const defaults = [
        {
          command:
            COMPLIANCE_DOCS_DROPDOWN_MAPPER[LABEL_FOR_PRIVACY_FORM].command,
          label: COMPLIANCE_DOCS_DROPDOWN_MAPPER[LABEL_FOR_PRIVACY_FORM].label,
          disabled: true,
        },
      ]
      if (!this.isCommercial) {
        defaults.push({
          command:
            COMPLIANCE_DOCS_DROPDOWN_MAPPER[LABEL_FOR_CREDIT_GUIDE].command,
          label: COMPLIANCE_DOCS_DROPDOWN_MAPPER[LABEL_FOR_CREDIT_GUIDE].label,
          disabled: true,
        })
        defaults.push({
          command:
            COMPLIANCE_DOCS_DROPDOWN_MAPPER[LABEL_FOR_CREDIT_QUOTE].command,
          label: COMPLIANCE_DOCS_DROPDOWN_MAPPER[LABEL_FOR_CREDIT_QUOTE].label,
          disabled: true,
        })
      }
      defaults.push({
        command:
          COMPLIANCE_DOCS_DROPDOWN_MAPPER[LABEL_FOR_DOCUSIGN_CERT].command,
        label: COMPLIANCE_DOCS_DROPDOWN_MAPPER[LABEL_FOR_DOCUSIGN_CERT].label,
        disabled: true,
      })
      return defaults
    },
  },
}
