//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { format } from 'date-fns'
import creditReportDialog from '../UtilComponents/creditReportDialog.vue'
import creditFetchDialog from '../UtilComponents/CreditFetchDialog.vue'

import {
  CREDIT_REPORT_SELECT_APPLICANT_TITLE,
  CREDIT_REPORT_PRIVACY_CREDIT_GUIDE,
  CREDIT_REPORT_PRIVACY_SENT_AT,
  CREDIT_REPORT_PRIVACY_SIGNED_AT,
  LAST_ERROR,
  CREDIT_REPORT_CREDIT_CHECK_HISTORY_TITLE,
  CREDIT_REPORT_APPLICANT_TITLE,
  CREDIT_REPORT_CREDIT_SCORE_TITLE,
  CREDIT_REPORT_ONE_SCORE_TITLE,
  CREDIT_REPORT_COMPREHENSIVE_TITLE,
  CREDIT_REPORT_LAST_CREATED_AT_TITLE,
  CREDIT_REPORT_TITLE,
  CREDIT_CHECK_TITLE,
  CREDIT_REPORT_PENDING,
  ERROR_TYPE_CREDIT_FILE_NOT_FOUND,
  ERROR_TYPE_NO_EXISTING_CREDIT_FILE_FOUND,
  LABEL_CREDIT_HISTORY,
  USER_SESSION_DATA_KEY,
  LABEL_COMMERCIAL,
} from '~/constants'
import {
  FINANCE_APPLICATION_COMMERCIAL_TAB_COMPLIANCE_KEY,
  FINANCE_APPLICATION_TAB_ASSET_DETAILS_KEY,
  LABEL_DOCUMENT_PRIMARY,
  LABEL_DOWNLOAD,
  LABEL_CREDIT_REFETCH,
  LABEL_SHARED,
  LABEL_DOCUMENT_SECONDARY,
  LABEL_NUMBER_OF_DIRECTORS,
  LABEL_NUMBER_OF_SHAREHOLDERS,
  CONSUMER_CREDIT_FILES_TITLE,
  COMMERCIAL_CREDIT_FILES_TITLE,
} from '~/constants/applications'
import { changeRoute } from '~/helpers/changeRoute'
import { getSignedURL } from '~/utils/url.js'
import CreditHistoryDrawer from '../CreditHistoryDrawer.vue'
import { axiosErrorMessageExtractor } from '../../utils/error'
import { getLatestCreditEnquiry } from '~/helpers/creditScore'
import { mapErrorType } from '~/helpers/creditScore'
import { LABEL_BUSINESS_NAME } from '~/constants/referrals'

export default {
  components: {
    creditReportDialog,
    CreditHistoryDrawer,
    creditFetchDialog,
  },
  props: {
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      creditEnquiriesData: { ...this.primaryData, ...this.secondaryData },
      runCreditDialog: false,
      CREDIT_REPORT_SELECT_APPLICANT_TITLE,
      CREDIT_REPORT_PRIVACY_CREDIT_GUIDE,
      CREDIT_REPORT_PRIVACY_SENT_AT,
      CREDIT_REPORT_PRIVACY_SIGNED_AT,
      LAST_ERROR,
      CREDIT_REPORT_CREDIT_CHECK_HISTORY_TITLE,
      CREDIT_REPORT_APPLICANT_TITLE,
      CREDIT_REPORT_CREDIT_SCORE_TITLE,
      CREDIT_REPORT_ONE_SCORE_TITLE,
      CREDIT_REPORT_COMPREHENSIVE_TITLE,
      CREDIT_REPORT_LAST_CREATED_AT_TITLE,
      CREDIT_REPORT_TITLE,
      CREDIT_CHECK_TITLE,
      LABEL_DOCUMENT_PRIMARY,
      LABEL_DOCUMENT_SECONDARY,
      CREDIT_REPORT_PENDING,
      ERROR_TYPE_NO_EXISTING_CREDIT_FILE_FOUND,
      LABEL_CREDIT_HISTORY,
      LABEL_DOWNLOAD,
      LABEL_CREDIT_REFETCH,
      LABEL_SHARED,
      USER_SESSION_DATA_KEY,
      LABEL_COMMERCIAL,
      LABEL_NUMBER_OF_DIRECTORS,
      LABEL_NUMBER_OF_SHAREHOLDERS,
      LABEL_BUSINESS_NAME,
      CONSUMER_CREDIT_FILES_TITLE,
      COMMERCIAL_CREDIT_FILES_TITLE,
      selectedIndex: null,
      dialogData: null,
      showDialog: false,
      showFetchDialog: false,
      dialogFor: null,
      applicantName: null,
      applicantId: null,
      refetchButtonLoader: false,
      format,
      mapErrorType,
      checkApplicationIsSettled: null,
      isCreditDataLoading: false,
    }
  },
  computed: {
    tableData() {
      let tableRows = [
        this.generateCreditRowFromApplicant(
          this.$props.primaryData,
          this.$props.primaryData.primaryApplicant
        ),
      ]
      this.$props.secondaryData.forEach((data) => {
        tableRows.push(
          this.generateCreditRowFromApplicant(data, data.applicant)
        )
      })
      return tableRows
    },
    commercialCreditData() {
      return this.$props.sharedData.businessData
    },
    /* eslint-enable */
  },
  mounted() {
    this.$nuxt.$on('closeDialog', () => {
      this.showDialog = false
    })
    this.$nuxt.$on('close-credit-fetch-dialog', () => {
      this.showFetchDialog = false
    })
    this.$nuxt.$on('close-loader', () => {
      this.isCreditDataLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },

  methods: {
    generateCreditRowFromApplicant(data, applicant) {
      this.isCreditDataLoading = true
      const { creditEnquiries, eSignRequestedAt, eSignAt, applicantType } = data
      setTimeout(() => {
        //Display loader for half second to load table data properly
        this.isCreditDataLoading = false
      }, 500)
      return {
        _id: applicant._id,
        fullName:
          (applicant && `${applicant.firstName} ${applicant.lastName}`) ||
          CREDIT_REPORT_PENDING,
        creditScore:
          (creditEnquiries && this.fetchCreditScore(creditEnquiries)) ||
          CREDIT_REPORT_PENDING,
        oneScore: this.fetchOneScore(creditEnquiries) || CREDIT_REPORT_PENDING,
        createdAt:
          (data?.creditEnquiries &&
            this.fetchLastCreatedAt(creditEnquiries) &&
            format(
              new Date(this.fetchLastCreatedAt(creditEnquiries)),
              'dd/MM/yyyy'
            )) ||
          CREDIT_REPORT_PENDING,
        eSignRequestedAt:
          (data.eSignRequestedAt &&
            format(new Date(eSignRequestedAt), 'dd/MM/yyyy hh:mm aaa')) ||
          '',
        eSignAt:
          (data?.eSignAt &&
            format(new Date(eSignAt), 'dd/MM/yyyy hh:mm aaa')) ||
          '',
        creditFileUrl:
          (creditEnquiries && this.fetchCreditFile(creditEnquiries)) || '',
        applicantType,
        errorType:
          data?.creditEnquiries &&
          getLatestCreditEnquiry(creditEnquiries, true)?.errorType,
      }
    },
    async showCreditHistoryDrawer(index, isCommercialCredit = false) {
      if (isCommercialCredit) {
        const creditHistoryData =
          await this.generateCreditHistoryWithCreatorData(
            this.commercialCreditData
          )
        this.setCreditHistoryData(creditHistoryData)
      } else {
        const tableRowData = this.tableData[index]
        if (tableRowData.applicantType === LABEL_DOCUMENT_PRIMARY) {
          const creditHistoryData =
            await this.generateCreditHistoryWithCreatorData(this.primaryData)
          this.setCreditHistoryData(creditHistoryData)
        } else if (tableRowData.applicantType === LABEL_DOCUMENT_SECONDARY) {
          const creditHistoryData =
            await this.generateCreditHistoryWithCreatorData(
              // subtracting 1 because first item in tableData is primary applicant
              this.secondaryData[index - 1]
            )
          this.setCreditHistoryData(creditHistoryData)
        }
      }
    },
    async generateCreditHistoryWithCreatorData(data) {
      const userData = JSON.parse(localStorage.getItem('auth.user'))
      let creditData = { ...data }
      if (creditData.creditEnquiries) {
        creditData.creditEnquiries = await Promise.all(
          creditData.creditEnquiries.map(async (creditEnquiry) => {
            let createdByData = null
            if (creditEnquiry.createdBy) {
              if (creditEnquiry.createdBy === userData._id) {
                createdByData = userData
              } else {
                const createdById = creditEnquiry.createdBy
                createdByData = await this.$axios
                  .get(`/user/${createdById}`)
                  .then((response) => response.data)
              }
            }
            return {
              ...creditEnquiry,
              createdByData,
            }
          })
        )
      }

      return creditData
    },
    setCreditHistoryData(data) {
      this.$nuxt.$emit('show-drawer', {
        show: true,
        creditData: data,
      })
    },
    disableRefetch(scope, isCommercialCredit = false) {
      if (isCommercialCredit) {
        return (
          !this.commercialCreditData.creditEnquiries?.at(-1)?.errorType ||
          this.commercialCreditData.creditEnquiries?.at(-1)?.errorType !==
            ERROR_TYPE_CREDIT_FILE_NOT_FOUND
        )
      } else {
        return (
          !this.tableData[scope.$index].errorType ||
          this.tableData[scope.$index].errorType !==
            ERROR_TYPE_CREDIT_FILE_NOT_FOUND
        )
      }
    },
    hasDocumentUrl(scope) {
      return (
        Array.isArray(this.tableData) &&
        this.tableData.length > 0 &&
        this.tableData[scope?.$index] &&
        this.tableData[scope?.$index].creditFileUrl
      )
    },
    getDocumentUrl(scope) {
      if (this.hasDocumentUrl(scope)) {
        return getSignedURL(
          this.tableData[scope.$index].creditFileUrl,
          this.$auth
        )
      }
    },
    async refetch(data, isCommercialCredit = false) {
      try {
        this.refetchButtonLoader = true
        const { id, applicationId } = this.$route.params

        let result
        if (isCommercialCredit) {
          result = await this.$store.dispatch(
            'applications/runRefetchCommercialCreditEnquiryFile',
            {
              id,
              applicationId,
            }
          )
        } else {
          const { _id: applicantId } = data.row
          result = await this.$store.dispatch(
            'applications/runRefetchCreditEnquiryFile',
            {
              id,
              applicationId,
              applicantId,
            }
          )
        }
        if (result?.data?.isError) {
          this.showError = true
        }
      } catch (err) {
        this.showError = true
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.refetchButtonLoader = false
      }
    },
    download(file, isCommercialCredit = false) {
      if (isCommercialCredit || file.row) {
        this.$axios({
          method: 'get',
          url: getSignedURL(
            isCommercialCredit
              ? this.commercialCreditData.creditEnquiries?.at(-1)?.creditFileUrl
              : file.row.creditFileUrl,
            this.$auth
          ),
          responseType: 'blob',
        }).then((response) => {
          if (response !== undefined) {
            this.downloadFile(response, file)
          }
        })
      }
    },
    downloadFile(response, file) {
      let headers = response.headers
      let blob = new Blob([response.data], { type: headers['content-type'] })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'VSA report'
      link.click()
      link.remove()
    },
    /* eslint-disable */
    openModal(applicant, isCommercialCredit = false) {
      this.showDialog = true
      this.dialogFor = isCommercialCredit
        ? LABEL_COMMERCIAL
        : applicant.applicantType
      this.dialogData = isCommercialCredit
        ? { fullName: this.commercialCreditData.businessName }
        : applicant
    },
    openCreditFetchDialog() {
      this.showFetchDialog = true
    },
    /* eslint-enable */
    async backToPrevious() {
      if (this.isCommercial) {
        return this.$nuxt.$emit('changeTab', {
          tab: FINANCE_APPLICATION_COMMERCIAL_TAB_COMPLIANCE_KEY,
        })
      }
      const { id, applicationId } = this.$route.params
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: 'compliance',
        },
      })
      this.$nuxt.$emit('updateComponent', 'compliance')
    },
    async submit(isRedirect) {
      if (this.isCommercial) {
        return this.$nuxt.$emit('changeTab', {
          tab: FINANCE_APPLICATION_TAB_ASSET_DETAILS_KEY,
        })
      }
      const { id, applicationId } = this.$route.params
      const nextTab = !this.sharedData?.securedFinance
        ? 'loanDetails'
        : 'assetDetails'
      if (isRedirect) {
        await changeRoute({
          route: this.$route,
          router: this.$router,
          path: `/finance/applications/${id}/${applicationId}`,
          query: {
            maintab: nextTab,
          },
        })
        this.$nuxt.$emit('updateComponent', nextTab)
      }
    },
    fetchCreditFile(creditEnquiries) {
      return getLatestCreditEnquiry(creditEnquiries, true)?.creditFileUrl ?? ''
    },
    fetchCreditScore(creditEnquiries) {
      return (
        getLatestCreditEnquiry(creditEnquiries)?.creditScore ??
        CREDIT_REPORT_PENDING
      )
    },
    fetchOneScore(creditEnquiries) {
      return (
        getLatestCreditEnquiry(creditEnquiries)?.oneScore ??
        CREDIT_REPORT_PENDING
      )
    },
    fetchLastCreatedAt(creditEnquiries) {
      return getLatestCreditEnquiry(creditEnquiries)?.createdAt
    },
  },
}
